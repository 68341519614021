import { Controller } from '@hotwired/stimulus';
import deLocaleObject from 'date-fns/locale/de';
import { ComboChart } from '@carbon/charts';
import { ChartTabularData, ComboChartOptions } from '@carbon/charts/interfaces';

/* stimulusFetch: 'lazy' */
export default class ComboChartController extends Controller {
  declare optionsValue: ComboChartOptions;
  declare dataValue: ChartTabularData;
  static values = {
    data: Array,
    options: Object,
  };

  chart: ComboChart;

  connect() {
    this.renderChart();
  }

  disconnect() {
    this.element.innerHTML = '';
  }

  renderChart() {
    const options = this.optionsValue;
    if (options.timeScale) {
      options.timeScale.localeObject = deLocaleObject;
    }

    this.chart = new ComboChart(this.element, {
      data: this.dataValue,
      options,
    });
  }
}
