import { Controller } from '@hotwired/stimulus';
import L from 'leaflet';

export type Facility = {
  capacity: number | null;
  displayName: string;
  finalDecomissioningAt: string | null;
  hubHeight: number | null;
  initialStartupAt: string | null;
  internalNumber: string;
  location: number[] | null;
  manufacturer: string | null;
  rotorDiameter: number | null;
  state: string;
  stateTranslated: string;
  typeManufacturer: string;
  typeOfficial: string;
};

export const FACILITY_ICON_OTHER = L.icon({
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  iconUrl: require('images/marker-icon_other.png'),
  iconRetinaUrl: require('images/marker-icon_other@2x.png'),
});

export const FACILITY_ICON = L.icon({
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  iconUrl: require('images/marker-icon.png'),
  iconRetinaUrl: require('images/marker-icon@2x.png'),
});

export const defaulTileLayer = L.tileLayer(
  `/tile-proxy/mapbox/{z}/{x}/{y}?styleId={id}&devicePixelRatio=${window.devicePixelRatio === 2 ? '@2x' : ''}&access_token={accessToken}`,
  {
    attribution:
      '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    tileSize: 512,
    maxZoom: 18,
    zoomOffset: -1,
    id: 'mapbox/streets-v12',
    accessToken: 'pk.eyJ1IjoiZmxvcmlhbmJvZWduZXIiLCJhIjoiY2xvZnI3bW1qMHF5NDJrbXZldzRvd25veCJ9.qWgv2a9-ZLY_VG25ZZZJ4g',
  },
);

export const satelliteTileLayer = L.tileLayer(
  `/tile-proxy/mapbox/{z}/{x}/{y}?styleId={id}&devicePixelRatio=${window.devicePixelRatio === 2 ? '@2x' : ''}&access_token={accessToken}`,
  {
    attribution:
      '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    tileSize: 512,
    maxZoom: 18,
    zoomOffset: -1,
    id: 'mapbox/satellite-streets-v12',
    accessToken: 'pk.eyJ1IjoiZmxvcmlhbmJvZWduZXIiLCJhIjoiY2xvZnI3bW1qMHF5NDJrbXZldzRvd25veCJ9.qWgv2a9-ZLY_VG25ZZZJ4g',
  },
);

/* stimulusFetch: 'lazy' */
export default class BaseMapController extends Controller<HTMLElement> {
  declare mapTarget: HTMLElement;
  static targets = ['map'];

  map: L.Map | null = null;

  connect() {
    this.map = L.map(this.mapTarget, {
      zoomSnap: 0.2,
      maxZoom: 17,
      minZoom: 10,
      zoomControl: false,
      attributionControl: false,
    });
    this.map.on('zoomend', () => this.setZoomControlState());

    defaulTileLayer.addTo(this.map);
  }

  disconnect() {
    this.map?.remove();
  }

  zoomIn(e: PointerEvent) {
    e.preventDefault();
    this.map?.zoomIn();
  }

  zoomOut(e: PointerEvent) {
    e.preventDefault();
    this.map?.zoomOut();
  }

  setZoomControlState() {
    const zoomInControl = this.element.querySelector('[data-action$="#zoomIn"]');
    const zoomOutControl = this.element.querySelector('[data-action$="#zoomOut"]');
    if (this.map?.getZoom() === this.map?.getMaxZoom()) {
      zoomInControl?.setAttribute('disabled', 'disabled');
    } else {
      zoomInControl?.removeAttribute('disabled');
    }
    if (this.map?.getZoom() === this.map?.getMinZoom()) {
      zoomOutControl?.setAttribute('disabled', 'disabled');
    } else {
      zoomOutControl?.removeAttribute('disabled');
    }
  }
}
