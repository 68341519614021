import { Controller } from '@hotwired/stimulus';

type FileInfo = {
  id: string;
  filename: string | null;
};

/* stimulusFetch: 'lazy' */
export default class AttachmentFileUploadController extends Controller {
  declare inputTarget: HTMLInputElement;
  declare prototypeTarget: HTMLTemplateElement;
  declare dropzoneTarget: HTMLDivElement;
  declare progressBarTarget: HTMLProgressElement;
  declare previewTarget: HTMLDivElement;
  static targets = ['input', 'prototype', 'dropzone', 'progressBar', 'preview'];

  declare urlValue: string;
  static values = {
    url: String,
  };

  declare uploadProgress: string[];
  declare files: FileInfo[];

  connect(): void {
    this.files = [];
    this.uploadProgress = [];

    this.setupInitialFiles();
    this.setupInputs();
  }

  setupInitialFiles(): void {
    const inputs: NodeListOf<HTMLDivElement> = this.previewTarget.querySelectorAll('.item');
    inputs.forEach((item: HTMLDivElement) => {
      // Add initial inputs to files
      const input = item.querySelector('input') as HTMLInputElement;

      // Get file instance
      const file = {
        id: input.value,
        filename: item.dataset.filename ? item.dataset.filename : '',
      };
      this.files.push(file);

      const media = `
        <div class="file">
          <div class="media">
            <div class="media__body pl--xs"><div class="truncate">${file.filename}</div></div>
            <div class="media__figure--reverse"><button type="button" class="btn" data-id="${file.id}">Entfernen</svg></button></div>
          </div>
        </div>
      `;

      item.insertAdjacentHTML('beforeend', media);

      // Add event listener to remove button
      const button = item.querySelector('button') as HTMLButtonElement;
      button.addEventListener('click', (e) => {
        e.preventDefault();
        this.removeFile(e.target as HTMLButtonElement);
      });
    });
  }

  setupInputs(): void {
    const preventDefaults = (e: Event): void => {
      e.preventDefault();
      e.stopPropagation();
    };

    // Prevent default drag behaviors
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      this.dropzoneTarget.addEventListener(eventName, (e) => preventDefaults(e), false);
      document.body.addEventListener(eventName, (e) => preventDefaults(e), false);
    });

    // Highlight drop area when item is dragged over it
    ['dragenter', 'dragover'].forEach((eventName: string) => {
      this.dropzoneTarget.addEventListener(eventName, () => this.dropzoneTarget.classList.add('has-drag-over'), false);
    });

    ['dragleave', 'drop'].forEach((eventName: string) => {
      this.dropzoneTarget.addEventListener(
        eventName,
        () => this.dropzoneTarget.classList.remove('has-drag-over'),
        false,
      );
    });

    // Handle dropped files
    this.dropzoneTarget.addEventListener('drop', (e) => this.handleDrop(e), false);

    this.inputTarget.addEventListener('change', () => {
      if (this.inputTarget.files) {
        this.handleFiles(this.inputTarget.files);
        this.inputTarget.value = '';
      }
    });
  }

  handleDrop(e: DragEvent) {
    const dt = e.dataTransfer;

    if (dt) {
      const { files } = dt;

      this.handleFiles(files);
    }
  }

  initializeProgress(numFiles: number) {
    this.progressBarTarget.value = 0;
    this.uploadProgress = [];

    for (let i = numFiles; i > 0; i -= 1) {
      this.uploadProgress.push('0');
    }
  }

  updateProgress(fileNumber: number, percent: number) {
    const total = this.uploadProgress.reduce((tot, curr) => tot + parseInt(curr, 10), 0) / this.uploadProgress.length;
    this.uploadProgress[fileNumber] = percent.toString();
    this.progressBarTarget.value = total;
  }

  handleFiles(filelist: FileList): void {
    const files = [...filelist];
    this.initializeProgress(files.length);
    files.forEach((file, i) => this.uploadFile(file, i));
  }

  addFile(file: FileInfo): void {
    // Add new file to files
    this.files.push({
      id: file.id,
      filename: file.filename,
    });

    // Render new item for file
    const index = this.previewTarget.querySelectorAll('.item').length + 1;
    const clone = this.prototypeTarget.content.cloneNode(true) as HTMLElement;
    const item = clone.querySelector('.item') as HTMLDivElement;

    // Warn if no item is found
    if (!item) {
      console.warn('No wrapper .item in template.');
    }

    item.id = item.id.replace(/__name__/g, index.toString());

    const formEl = clone.querySelector('.form-element') as HTMLDivElement;
    formEl.hidden = true;

    // Corrent input attributes
    const input = clone.querySelector('input');
    if (input) {
      input.id = input.id.replace(/__name__/g, index.toString());
      input.name = input.name.replace(/__name__/g, index.toString());
      input.value = file.id;
    }

    const fileEl = document.createElement('div');
    fileEl.classList.add('file');
    const media = `
      <div class="media">
        <div class="media__body pl--xs"><div class="truncate">${file.filename}</div></div>
        <div class="media__figure--reverse">
          <button type="button" class="btn">Entfernen</svg></button>
        </div>
      </div>
    `;
    fileEl.innerHTML = media;

    // Add event listener to remove file
    const btn = fileEl.querySelector('button') as HTMLButtonElement;
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      this.removeFile(e.target as HTMLButtonElement);
    });

    // Add fileEl to item
    item.append(fileEl);

    this.previewTarget.append(clone);
  }

  removeFile(btn: HTMLButtonElement): void {
    const item = btn.closest('.item') as HTMLDivElement;
    const input = item.querySelector('input') as HTMLInputElement;
    this.files.splice(
      this.files.findIndex((file) => file.id === input.value),
      1,
    );
    this.previewTarget.removeChild(item);
  }

  uploadFile(file: File, i: number): void {
    const url = this.urlValue.replace('%23fileName%23', file.name);
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);

    // Update progress
    xhr.upload.addEventListener('progress', (e) => {
      this.updateProgress(i, (e.loaded * 100.0) / e.total || 100);
    });

    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const response = JSON.parse(xhr.responseText) as FileInfo;
        this.updateProgress(i, 100);
        this.addFile(response);
      } else if (xhr.readyState === 4 && xhr.status !== 200) {
        // Error. Inform the user
      }
    });

    xhr.send(file);
  }
}
