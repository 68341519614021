import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class InputAutogrowController extends Controller<HTMLInputElement> {
  connect(): void {
    this.setAutogrowContent();
    this.element.addEventListener('input', () => this.setAutogrowContent());
  }

  setAutogrowContent() {
    this.element.parentNode?.setAttribute('data-input-autogrow', this.element.value);
  }
}
