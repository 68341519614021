import { Controller } from '@hotwired/stimulus';
import { setRouteSetting } from '../utils/route-settings';

/* stimulusFetch: 'lazy' */
export default class NewParkGuideController extends Controller {
  hideGuide(): void {
    setRouteSetting('park_dashboard', 'hide_new_park_guide', true);

    this.element.setAttribute('hidden', 'hidden');
  }
}
