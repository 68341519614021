import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class VotingEditDraftController extends Controller {
  setSubmitLabel(event: Event): void {
    const submitEl = document.getElementById('voting_draft_saveDraft');
    const toggle = event.target as HTMLInputElement;

    if (submitEl) {
      if (toggle.checked) {
        submitEl.dataset.label = submitEl?.innerText;
        if (submitEl.dataset.labeldrafttoopen) {
          submitEl.innerText = submitEl.dataset.labeldrafttoopen;
        }
      } else if (submitEl.dataset.label) {
        submitEl.innerText = submitEl.dataset.label;
      }
    }
  }
}
