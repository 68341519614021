import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';
import { v4 as uuidv4 } from 'uuid';
import { apply, isSupported } from '@oddbird/popover-polyfill/fn';
import { anchorPositioning } from '../utils/dropdown';

/* stimulusFetch: 'lazy' */
export default class DropdownController extends Controller<HTMLElement> {
  btn: HTMLButtonElement | null;
  targetId: string | null;
  dropdown: HTMLElement | null;

  connect() {
    if (!isSupported()) {
      apply();
    }

    this.btn = this.element.querySelector(':scope > button, :scope > a');

    if (this.btn?.hasAttribute('aria-controls')) {
      this.targetId = this.btn?.getAttribute('aria-controls');
      this.dropdown = document.querySelector(`#${this.targetId}`);
    } else {
      this.dropdown = this.element.querySelector(':scope > .dropdown');
    }

    if (!this.btn?.hasAttribute('aria-expanded')) {
      this.btn?.setAttribute('aria-expanded', 'false');
    }

    // If no id Id set, generate a new Id
    if (!this.targetId) {
      this.targetId = `d-${uuidv4()}`;
      this.btn?.setAttribute('aria-controls', this.targetId as string);
      this.dropdown?.setAttribute('id', this.targetId as string);
    }

    // Setup popover attributes
    this.btn?.setAttribute('popovertarget', this.targetId);
    this.dropdown?.setAttribute('popover', '');

    // Button events
    this.btn?.addEventListener('click', (event: MouseEvent) => {
      event.preventDefault();
      this.toggle();
    });

    this.btn?.addEventListener('keydown', (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          this.toggle();
          break;
        case 'Escape':
          event.preventDefault();
          this.close();
          break;
        default:
          break;
      }
    });

    this.element?.addEventListener('keydown', (event: KeyboardEvent) => {
      // Esc
      if (event.key === 'Escape') {
        this.close();
      }
    });

    this.dropdown?.addEventListener('focusout', (event: FocusEvent) => {
      if (this.dropdown?.contains((event.relatedTarget ? event.relatedTarget : event.target) as HTMLElement)) return;

      this.close();
    });

    if (this.btn && this.dropdown) {
      useClickOutside(this, { element: this.element });
      anchorPositioning(this.btn, this.dropdown);
    }
  }

  clickOutside() {
    if (this.btn?.getAttribute('aria-expanded') === 'true') {
      this.close();
    }
  }

  toggle() {
    if (this.btn?.getAttribute('aria-expanded') === 'true') {
      this.close();
    } else {
      this.show();
    }
  }

  show() {
    this.btn?.setAttribute('aria-expanded', 'true');
    this.dropdown?.showPopover();
    if (this.dropdown?.parentNode !== this.element) {
      this.dropdown?.removeAttribute('hidden');
    }
  }

  close() {
    this.btn?.setAttribute('aria-expanded', 'false');
    this.dropdown?.hidePopover();
    if (this.dropdown?.parentNode !== this.element) {
      this.dropdown?.setAttribute('hidden', '');
    }
  }
}
