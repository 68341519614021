import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class ServiceeventsOverview extends Controller {
  setDateRange(event: Event) {
    const button = event.target as HTMLButtonElement;
    const leftDateInput = this.element.querySelector('#serviceevent_filter_openBetween_left_date') as HTMLInputElement;
    const rightDateInput = this.element.querySelector(
      '#serviceevent_filter_openBetween_right_date',
    ) as HTMLInputElement;
    const submitBtn = this.element.querySelector('#serviceevent_filter_submit') as HTMLButtonElement;

    if (button.dataset.leftDate && leftDateInput) {
      leftDateInput.value = button.dataset.leftDate;
    }
    if (button.dataset.rightDate && rightDateInput) {
      rightDateInput.value = button.dataset.rightDate;
    }

    submitBtn?.click();
  }
}
