import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class ToggleOnInputController extends Controller {
  connect() {
    if (this.element instanceof HTMLFieldSetElement) {
      const inputs: NodeListOf<HTMLInputElement> = this.element.querySelectorAll('input');
      const checked: NodeListOf<HTMLInputElement> = this.element.querySelectorAll('input:checked');
      const values = Array.from(checked).map((e) => e.value);

      // Toggle on inital values
      if (inputs.length > 0) {
        this.toggleSections(inputs[0].name, values);
      }
      this.element.addEventListener('change', () => {
        const checked: NodeListOf<HTMLInputElement> = this.element.querySelectorAll('input:checked');
        const values = Array.from(checked).map((e) => e.value);
        this.toggleSections(inputs[0].name, values);
      });
    } else {
      const input = this.element as HTMLInputElement;

      // Toggle on inital values
      this.toggleSections(input.name, input.type === 'checkbox' ? input.checked : input.value);

      input.addEventListener('change', () => {
        this.toggleSections(input.name, input.type === 'checkbox' ? input.checked : input.value);
      });
    }
  }

  cleanStrings(array: Array<string>): Array<string> {
    return array.map((e) => e.trim().replaceAll("'", ''));
  }

  toggleSections(name: string, value: boolean | string | Array<string>): void {
    const targets = document.querySelectorAll(`[data-target="${name}"]`) as NodeListOf<HTMLInputElement>;

    targets.forEach((target) => {
      if (target.hasAttribute('data-show-on')) {
        const showOnAttr = target.dataset.showOn?.split('||');

        if (showOnAttr) {
          const showOn = this.cleanStrings(showOnAttr);

          if (
            typeof value === 'boolean'
              ? value === true
              : value !== '' && typeof value === 'string'
                ? showOn.includes(value)
                : showOn.some((r: string) => value.indexOf(r) >= 0)
          ) {
            target.hidden = false;
          } else {
            target.hidden = true;
          }
        }
      }

      if (target.hasAttribute('data-hide-on')) {
        const hideOnAttr = target.dataset.hideOn?.split(' ');

        if (hideOnAttr) {
          const hideOn = this.cleanStrings(hideOnAttr);

          if (
            typeof value === 'boolean'
              ? value === true
              : value !== '' && typeof value === 'string'
                ? hideOn.includes(value)
                : hideOn.some((r: string) => value.indexOf(r) >= 0)
          ) {
            target.hidden = true;
          } else {
            target.hidden = false;
          }
        }
      }
    });
  }
}
