import { Controller } from '@hotwired/stimulus';
import { post } from '../utils/api';

/* stimulusFetch: 'lazy' */
export default class SluggerController extends Controller {
  declare hasUrlValue: boolean;
  declare urlValue: string;
  declare hasDateInputIdValue: boolean;
  declare dateInputIdValue: string;
  static values = {
    url: String,
    dateInputId: String,
  };

  declare hasInputTarget: boolean;
  declare inputTarget: HTMLInputElement;
  declare hasOutputTarget: boolean;
  declare outputTarget: HTMLInputElement;
  static targets = ['input', 'output'];

  connect() {
    if (this.hasInputTarget && this.hasOutputTarget && this.hasUrlValue) {
      this.inputTarget.addEventListener('blur', () => {
        if (!this.outputTarget.value.trim()) {
          this.generateSlug();
        }
      });
    }
  }

  generateSlug() {
    if (this.inputTarget.value.trim()) {
      let toSlug = this.inputTarget.value.trim();
      if (this.hasDateInputIdValue) {
        const dateInput = document.getElementById(this.dateInputIdValue) as HTMLInputElement | undefined;
        if (dateInput) {
          toSlug = `${dateInput.value.split('T')[0]}-${toSlug}`;
        }
      }
      post(this.urlValue, {}, toSlug).then((response) => {
        this.outputTarget.value = response;
      });
    }
  }
}
