import { Controller } from '@hotwired/stimulus';
import { isFocusable } from 'tabbable';

/* stimulusFetch: 'lazy' */
export default class Menubar extends Controller<HTMLElement> {
  menuitems: null | NodeListOf<HTMLElement> = null;

  popups = [];
  menuitemGroups = {};
  menuOrientation = {};
  isPopup = {};

  firstMenuitem = {}; // see Menubar init method
  lastMenuitem = {}; // see Menubar init method

  connect(): void {
    this.initMenu(this.element);

    window.addEventListener('pointerdown', this.onBackgroundPointerdown.bind(this), true);
  }

  getMenuitems(domNode: HTMLElement) {
    const nodes: HTMLElement[] = [];

    const initMenu = this.initMenu.bind(this);
    const getGroupId = this.getGroupId.bind(this);
    const { menuitemGroups } = this;
    const { popups } = this;

    function findMenuitems(node: HTMLElement, group: boolean) {
      let role;
      let flag;
      let groupId;

      while (node) {
        flag = true;
        role = node.getAttribute('role') ?? node.getAttribute('type');

        switch (role) {
          case 'menu':
            node.tabIndex = -1;
            initMenu(node);
            flag = false;
            break;

          case 'group':
            groupId = getGroupId(node);
            menuitemGroups[groupId] = [];
            break;

          case 'button':
          case 'menuitem':
          case 'menuitemradio':
          case 'menuitemcheckbox':
            if (node.getAttribute('aria-haspopup') === 'true') {
              popups.push(node);
            }
            nodes.push(node);
            if (group) {
              group.push(node);
            }
            break;

          default:
            break;
        }

        if (flag && node.firstElementChild) {
          findMenuitems(node.firstElementChild, menuitemGroups[groupId]);
        }

        node = node.nextElementSibling;
      }
    }

    findMenuitems(domNode.firstElementChild, false);

    return nodes;
  }

  initMenu(menu: HTMLElement) {
    const menuitems = this.getMenuitems(menu);

    const menuId = this.getMenuId(menu);
    this.menuOrientation[menuId] = this.getMenuOrientation(menu);
    this.isPopup[menuId] = menu.getAttribute('role') === 'menu';

    this.menuitemGroups[menuId] = [];
    this.firstMenuitem[menuId] = null;
    this.lastMenuitem[menuId] = null;

    menuitems.forEach((menuitem) => {
      menuitem.tabIndex = -1;
      this.menuitemGroups[menuId].push(menuitem);

      menuitem.addEventListener('keydown', (event) => this.onKeydown(event));
      menuitem.addEventListener('click', (event) => this.onMenuitemClick(event));

      if (!this.firstMenuitem[menuId]) {
        menuitem.tabIndex = 0;
        this.firstMenuitem[menuId] = menuitem;
      }
      this.lastMenuitem[menuId] = menuitem;
    });
  }

  //
  // Focus management methods
  //

  setFocusToMenuitem(menuId: string, newMenuitem: HTMLElement) {
    const isAnyPopupOpen = this.isAnyPopupOpen();

    this.closePopupAll(newMenuitem);

    if (this.hasPopup(newMenuitem)) {
      if (isAnyPopupOpen) {
        this.openPopup(newMenuitem);
      }
    } else {
      const menu = this.getMenu(newMenuitem);
      const cmi = menu.parentElement?.previousElementSibling as HTMLElement | null;
      if (cmi && !this.isOpen(cmi)) {
        this.openPopup(cmi);
      }
    }

    if (this.hasPopup(newMenuitem)) {
      if (this.menuitemGroups[menuId]) {
        this.menuitemGroups[menuId].forEach((item) => {
          item.tabIndex = -1;
        });
      }
      newMenuitem.tabIndex = 0;
    }

    newMenuitem.focus();
  }

  setFocusToFirstMenuitem(menuId: string) {
    this.setFocusToMenuitem(menuId, this.firstMenuitem[menuId]);
  }

  setFocusToLastMenuitem(menuId: string) {
    this.setFocusToMenuitem(menuId, this.lastMenuitem[menuId]);
  }

  setFocusToPreviousMenuitem(menuId: string, currentMenuitem: HTMLElement) {
    let index = this.menuitemGroups[menuId].indexOf(currentMenuitem);
    let newMenuitem = this.menuitemGroups[menuId][index === 0 ? this.menuitemGroups[menuId].length - 1 : index - 1];

    while (newMenuitem && currentMenuitem !== newMenuitem) {
      if (isFocusable(newMenuitem)) {
        this.setFocusToMenuitem(menuId, newMenuitem);
        return newMenuitem;
      }
      index = this.menuitemGroups[menuId].indexOf(newMenuitem);
      newMenuitem = this.menuitemGroups[menuId][index === 0 ? this.menuitemGroups[menuId].length - 1 : index - 1];
    }

    return newMenuitem;
  }

  setFocusToNextMenuitem(menuId: string, currentMenuitem: HTMLElement) {
    let index = this.menuitemGroups[menuId].indexOf(currentMenuitem);
    let newMenuitem = this.menuitemGroups[menuId][this.menuitemGroups[menuId].length === index + 1 ? 0 : index + 1];

    while (newMenuitem && currentMenuitem !== newMenuitem) {
      if (isFocusable(newMenuitem)) {
        this.setFocusToMenuitem(menuId, newMenuitem);
        return newMenuitem;
      }
      index = this.menuitemGroups[menuId].indexOf(newMenuitem);
      newMenuitem = this.menuitemGroups[menuId][this.menuitemGroups[menuId].length === index + 1 ? 0 : index + 1];
    }

    return newMenuitem;
  }

  //
  // Utilities
  //

  getIdFromAriaLabel(node: HTMLElement): string | null {
    let id = node.getAttribute('aria-label');
    if (id) {
      id = id.trim().toLowerCase().replace(' ', '-').replace('/', '-');
    }
    return id;
  }

  getMenuOrientation(node: HTMLElement) {
    let orientation = node.getAttribute('aria-orientation');

    if (!orientation) {
      const role = node.getAttribute('role');

      switch (role) {
        case 'toolbar':
        case 'menubar':
          orientation = 'horizontal';
          break;

        case 'menu':
          orientation = 'vertical';
          break;

        default:
          break;
      }
    }

    return orientation;
  }

  getGroupId(node: HTMLElement) {
    let id = '';
    let role = node.getAttribute('role');

    while (node && role !== 'group' && role !== 'menu' && role !== 'menubar') {
      node = node.parentNode;
      if (node) {
        role = node.getAttribute('role');
      }
    }

    if (node) {
      id = `${role}-${this.getIdFromAriaLabel(node)}`;
    }

    return id;
  }

  getMenuId(node: HTMLElement) {
    let id = '';
    let role = node.getAttribute('role');

    while (node && role !== 'menu' && role !== 'menubar' && role !== 'toolbar') {
      node = node.parentNode;
      if (node) {
        role = node.getAttribute('role');
      }
    }

    if (node) {
      id = `${role}-${this.getIdFromAriaLabel(node)}`;
    }

    return id;
  }

  getMenu(menuitem: HTMLElement) {
    let menu = menuitem;
    let role = menuitem.getAttribute('role');

    while (menu && role !== 'menu' && role !== 'menubar' && role !== 'toolbar') {
      menu = menu.parentNode;
      if (menu) {
        role = menu.getAttribute('role');
      }
    }

    return menu;
  }

  toggleCheckbox(menuitem: HTMLElement) {
    if (menuitem.getAttribute('aria-checked') === 'true') {
      menuitem.setAttribute('aria-checked', 'false');
      return false;
    }
    menuitem.setAttribute('aria-checked', 'true');
    return true;
  }

  setRadioButton(menuitem: HTMLElement) {
    const groupId = this.getGroupId(menuitem);
    const radiogroupItems = this.menuitemGroups[groupId];
    radiogroupItems.forEach((item) => {
      item.setAttribute('aria-checked', 'false');
    });
    menuitem.setAttribute('aria-checked', 'true');
    return menuitem.textContent;
  }

  //
  // Popup menu methods
  //

  isAnyPopupOpen() {
    for (let i = 0; i < this.popups.length; i++) {
      if (this.popups[i].getAttribute('aria-expanded') === 'true') {
        return true;
      }
    }
    return false;
  }

  openPopup(menuitem: HTMLElement) {
    menuitem.setAttribute('aria-expanded', 'true');

    const popupMenu = menuitem.nextElementSibling?.querySelector('[role="menu"]');
    return this.getMenuId(popupMenu);
  }

  closePopup(menuitem: HTMLElement): HTMLElement {
    let menu;
    let cmi;

    if (this.hasPopup(menuitem)) {
      if (this.isOpen(menuitem)) {
        menuitem.setAttribute('aria-expanded', 'false');
      }
    } else {
      menu = this.getMenu(menuitem);
      cmi = menu.parentElement?.previousElementSibling as HTMLElement | null;
      cmi?.setAttribute('aria-expanded', 'false');
      cmi?.focus();
    }

    return cmi;
  }

  doesNotContain(popup, menuitem) {
    if (menuitem) {
      return !popup.nextElementSibling.contains(menuitem);
    }
    return true;
  }

  closePopupAll(menuitem: HTMLElement | boolean = false) {
    if (typeof menuitem !== 'object') {
      menuitem = false;
    }

    for (let i = 0; i < this.popups.length; i++) {
      const popup = this.popups[i];
      if (this.isOpen(popup) && this.doesNotContain(popup, menuitem)) {
        this.closePopup(popup);
      }
    }
  }

  hasPopup(menuitem: HTMLElement) {
    return menuitem.getAttribute('aria-haspopup') === 'true';
  }

  isOpen(menuitem: HTMLElement) {
    return menuitem.getAttribute('aria-expanded') === 'true';
  }

  // Menu event handlers

  onBackgroundPointerdown(event: PointerEvent) {
    const target = event.target as HTMLElement;
    if (!this.element.contains(target)) {
      this.closePopupAll();
    }
  }

  onKeydown(event: KeyboardEvent) {
    const target = event.currentTarget as HTMLElement;
    const { key } = event;
    let flag = false;
    const menuId = this.getMenuId(target);
    let id;
    let popupMenuId;
    let mi;
    let role;

    switch (key) {
      case ' ':
      case 'Enter':
        if (this.hasPopup(target)) {
          popupMenuId = this.openPopup(target);
          this.setFocusToFirstMenuitem(popupMenuId);
        } else {
          role = target.getAttribute('role');
          switch (role) {
            case 'menuitem':
              target.dispatchEvent(new Event('click'));
              break;
            case 'menuitemcheckbox':
              this.toggleCheckbox(target);
              break;
            case 'menuitemradio':
              this.setRadioButton(target);
              break;
            default:
              break;
          }
          this.closePopup(target);
        }
        flag = true;
        break;

      case 'ArrowDown':
      case 'Down':
        if (this.menuOrientation[menuId] === 'vertical') {
          this.setFocusToNextMenuitem(menuId, target);
          flag = true;
        } else if (this.hasPopup(target)) {
          event.preventDefault();
          popupMenuId = this.openPopup(target);
          this.setFocusToFirstMenuitem(popupMenuId);
          flag = true;
        }
        break;

      case 'Esc':
      case 'Escape':
        this.closePopup(target);
        flag = true;
        break;

      case 'Left':
      case 'ArrowLeft':
        if (this.menuOrientation[menuId] === 'horizontal') {
          this.setFocusToPreviousMenuitem(menuId, target);
          flag = true;
        } else {
          event.preventDefault();
          mi = this.closePopup(target);
          id = this.getMenuId(mi);
          mi = this.setFocusToPreviousMenuitem(id, mi);
          if (this.hasPopup(mi)) {
            this.openPopup(mi);
          }
        }
        break;

      case 'Right':
      case 'ArrowRight':
        if (this.menuOrientation[menuId] === 'horizontal') {
          this.setFocusToNextMenuitem(menuId, target);
          flag = true;
        } else {
          event.preventDefault();
          mi = this.closePopup(target);
          id = this.getMenuId(mi);
          mi = this.setFocusToNextMenuitem(id, mi);
          if (this.hasPopup(mi)) {
            this.openPopup(mi);
          }
        }
        break;

      case 'Up':
      case 'ArrowUp':
        if (this.menuOrientation[menuId] === 'vertical') {
          this.setFocusToPreviousMenuitem(menuId, target);
          flag = true;
        } else if (this.hasPopup(target)) {
          event.preventDefault();
          popupMenuId = this.openPopup(target);
          this.setFocusToLastMenuitem(popupMenuId);
          flag = true;
        }
        break;

      case 'Home':
      case 'PageUp':
        this.setFocusToFirstMenuitem(menuId);
        flag = true;
        break;

      case 'End':
      case 'PageDown':
        this.setFocusToLastMenuitem(menuId);
        flag = true;
        break;

      case 'Tab':
        this.closePopup(target);
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  onMenuitemClick(event: MouseEvent) {
    const target = event.currentTarget as HTMLElement;

    if (this.hasPopup(target)) {
      if (this.isOpen(target)) {
        this.closePopup(target);
      } else {
        const menuId = this.openPopup(target);
        this.setFocusToMenuitem(menuId, target);
      }
    } else {
      const role = target?.getAttribute('role');
      switch (role) {
        case 'menuitem':
          break;
        case 'menuitemcheckbox':
          this.toggleCheckbox(target);
          break;
        case 'menuitemradio':
          this.setRadioButton(target);
          break;
        default:
          break;
      }
      this.closePopup(target);
    }

    event.stopPropagation();
    event.preventDefault();
  }
}
