import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class ToggleLeadController extends Controller {
  connect() {
    this.element.addEventListener('click', () => {
      if (this.element.classList.contains('p--closed')) {
        this.element.classList.remove('p--closed');
      }
    });
  }
}
