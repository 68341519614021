import { Controller } from '@hotwired/stimulus';
import * as focusTrap from 'focus-trap';
import { useResize } from 'stimulus-use';

export default class SidebarController extends Controller {
  declare sidebarTarget: HTMLElement;
  declare toggleTarget: HTMLElement;
  static targets = ['sidebar', 'toggle'];
  trap: focusTrap.FocusTrap;

  connect(): void {
    if (this.toggleTarget && this.sidebarTarget) {
      this.element.setAttribute('data-nav', this.isNavOpen() ? 'open' : 'closed');

      this.trap = focusTrap.createFocusTrap(this.sidebarTarget, {
        allowOutsideClick: true,
      });

      useResize(this);
    }
  }

  disconnect(): void {
    // Deactivate the trap to prevent the warning for a focus-trap without any tabbable nodes
    this.trap.deactivate();
  }

  onSidebarKeydown() {
    if (this.isNavOpen()) {
      this.closeNav();
      this.toggleTarget?.focus();
    }
  }

  onElementClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target !== this.toggleTarget && this.isNavOpen() && !target.closest('[data-sidebar-target="sidebar"]')) {
      this.closeNav();
    }
  }

  isNavOpen() {
    return (
      this.toggleTarget.hasAttribute('aria-expanded') && this.toggleTarget.getAttribute('aria-expanded') === 'true'
    );
  }

  openNav() {
    this.toggleTarget?.setAttribute('aria-expanded', 'true');
    this.element.setAttribute('data-nav', 'open');
    this.sidebarTarget?.focus();
    this.trap.activate();
  }

  closeNav() {
    this.toggleTarget?.setAttribute('aria-expanded', 'false');
    this.element.setAttribute('data-nav', 'closed');
    this.trap.deactivate();
  }

  toglleNav() {
    if (this.isNavOpen()) {
      this.closeNav();
    } else {
      this.openNav();
    }
  }

  resize({ width }) {
    if (width > 1200) {
      this.closeNav();
    }
  }
}
