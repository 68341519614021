import { Node } from '@tiptap/core';
import { dropImagePlugin, UploadFn } from '../plugins/drop-image';

const imageBlock = (
  uploadFn: UploadFn,
  imageUploadUrlValue: string,
  filenameReplacePatternValue: string,
  maxFilenameLengthValue: number,
) => Node.create({
  name: 'image',
  group: 'block',

  selectable: true,
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      size: ['full', 'half', 'quarter'],
      alignment: ['inline', 'left', 'right'],
    };
  },

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (element) => element.getAttribute('src'),
      },
      alt: {
        default: null,
        parseHTML: (element) => element.getAttribute('alt'),
      },
      title: {
        default: null,
        parseHTML: (element) => element.getAttribute('title'),
      },
      size: {
        default: 'full',
        parseHTML: (element) => element.dataset.size,
        renderHTML: (attributes) => ({
          'data-size': attributes.size,
        }),
      },
      alignment: {
        default: 'inline',
        parseHTML: (element) => element.dataset.alignment,
        renderHTML: (attributes) => ({
          'data-alignment': attributes.alignment,
        }),
      },
    };
  },

  parseHTML: () => [
    {
      tag: 'img[src]',
      getAttrs: (dom) => {
        if (typeof dom === 'string') return {};
        const element = dom as HTMLImageElement;

        return {
          src: element.getAttribute('src'),
          title: element.getAttribute('title'),
          alt: element.getAttribute('alt'),
        };
      },
    },
  ],

  renderHTML: ({ node, HTMLAttributes }) => ['img', HTMLAttributes],

  addCommands: (attrs) => (state, dispatch) => {
    const { selection } = state;
    const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
    const node = this.type.create(attrs);
    const transaction = state.tr.insert(position, node);
    dispatch(transaction);
  },

  addProseMirrorPlugins() {
    return [dropImagePlugin(uploadFn, imageUploadUrlValue, filenameReplacePatternValue, maxFilenameLengthValue)];
  },
});

export default imageBlock;
