import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class ClipboardController extends Controller {
  declare textValue: string;
  static values = { text: String };

  copy(event: Event) {
    event.preventDefault();

    if (this.textValue) {
      navigator.clipboard.writeText(this.textValue);
    }
  }
}
