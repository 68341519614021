export interface SelectOption {
  label: string;
  value: number;
}

export type AutocompleteOption = {
  text: string | null;
  value: string;
  selected: boolean;
  disabled: boolean;
};

export const getSelectOptions = (selectEl: HTMLSelectElement): AutocompleteOption[] => {
  const filtered = [];
  const options = Array.from(selectEl.options);
  let option;
  for (let i = 0; i < options.length; i += 1) {
    option = options[i];
    const { value } = option;
    if (value.trim().length > 0) {
      filtered.push({
        text: option.textContent,
        value: option.value,
        selected: option.selected,
        disabled: option.disabled,
      });
    }
  }

  return filtered;
};

export const getSelectOptionByValue = (selectEl: HTMLSelectElement, value: string): HTMLOptionElement | null => selectEl.querySelector(`option[value="${value}"]`);

export const getSelectOptionByText = (selectEl: HTMLSelectElement, value: string): HTMLOptionElement | null => {
  let option = null;
  const options = selectEl.querySelectorAll('option');
  for (let i = 0; i < options.length; i += 1) {
    if (options[i].textContent?.toLowerCase() === value.toLowerCase()) {
      option = options[i];
      break;
    }
  }
  return option;
};

export const clearOptions = (listboxEl: HTMLElement): void => {
  listboxEl.replaceChildren();
};

export const getNextSelectableOption = (option: HTMLLIElement): HTMLLIElement => {
  // Get the next sibling element
  let sibling = option.nextElementSibling;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(':not([aria-disabled="true"])')) return sibling as HTMLLIElement;
    sibling = sibling.nextElementSibling;
  }

  return sibling || option;
};

export const getPreviousSelectableOption = (option: HTMLLIElement): HTMLLIElement => {
  // Get the previous sibling element
  let sibling = option.previousElementSibling;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(':not([aria-disabled="true"])')) return sibling as HTMLLIElement;
    sibling = sibling.previousElementSibling;
  }

  return sibling || option;
};

export const getFirstSelectableOption = (listboxEl: HTMLElement): HTMLLIElement | undefined => Array.from(listboxEl.querySelectorAll('[role=option]')).find(
  (option) => !option.getAttribute('aria-disabled'),
) as HTMLLIElement;

export const getLastSelectableOption = (listboxEl: HTMLElement): HTMLLIElement | undefined => Array.from(listboxEl.querySelectorAll('[role=option]'))
  .reverse()
  .find((option) => !option.getAttribute('aria-disabled')) as HTMLLIElement;

export const addOptions = (parent: HTMLSelectElement, options: SelectOption[]) => {
  const placeholder = document.createElement('select');
  options.forEach((item: SelectOption) => {
    const opt = document.createElement('option');
    opt.value = item.value.toString();
    opt.innerHTML = item.label;
    placeholder.add(opt);
  });

  parent.append(...placeholder.childNodes);
};

export const replaceOptions = (selectEl: HTMLSelectElement, options: SelectOption[]) => {
  const placeholder = document.createElement('select');
  options.forEach((item: SelectOption) => {
    const opt = document.createElement('option');
    opt.value = item.value.toString();
    opt.innerHTML = item.label;
    placeholder.add(opt);
  });

  selectEl.replaceChildren(...placeholder.childNodes);
};

export const highlightResult = (text: string, indices: ReadonlyArray<[number, number]>, minMatchChar: number) => indices
  .reduce((str, [start, end]) => {
    if (Math.abs(start - end) >= minMatchChar) {
      str[start] = `<span class="highlight">${str[start]}`;
      str[end] = `${str[end]}</span>`;
    }
    return str;
  }, text.split(''))
  .join('');
