import { autoUpdate, computePosition, flip } from '@floating-ui/dom';

function updatePosition(button: HTMLElement, dropdown: HTMLElement) {
  const { placement } = dropdown.dataset;
  const { alignment } = dropdown.dataset;
  return () => computePosition(button, dropdown, {
    placement: `${placement || 'bottom'}-${alignment || 'start'}`,
    middleware: [flip()],
  }).then(({ x, y }) => {
    Object.assign(dropdown.style, {
      'inset-block-start': `${y}px`,
      'inset-inline-start': `${x}px`,
    });
  });
}

function anchorPositioning(anchor: HTMLElement, popover: HTMLElement) {
  autoUpdate(anchor, popover, updatePosition(anchor, popover));
}

export { updatePosition, anchorPositioning };
