import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class CharacterCounterController extends Controller {
  declare maxLengthValue: number;
  declare messageValue: string;
  static values = {
    maxLength: {
      type: Number,
      default: 100,
    },
    message: {
      type: String,
      default: 'Es verbleiben %count% Zeichen.',
    },
  };

  declare fieldEl: HTMLInputElement;
  declare statusEl: HTMLElement;

  connect(): void {
    this.fieldEl = this.element as HTMLInputElement;
    this.statusEl = document.createElement('div');
    this.statusEl.setAttribute('role', 'status');
    this.statusEl.setAttribute('aria-live', 'polite');
    this.statusEl.classList.add('form-element__help');
    this.updateStatus(this.textLength() ? this.maxLengthValue - this.fieldEl.value.length : this.maxLengthValue);
    this.fieldEl.parentElement?.append(this.statusEl);
    this.fieldEl.addEventListener('input', () => this.onChange());
  }

  textLength() {
    return [...this.fieldEl.value].length;
  }

  onChange(): void {
    this.updateStatus(this.maxLengthValue - this.textLength());
  }

  updateStatus(remaining: number): void {
    this.statusEl.innerHTML = this.messageValue.replace(/%count%/, remaining.toString());
  }
}
