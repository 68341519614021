import { Controller } from '@hotwired/stimulus';

import { numberFormater } from '../../utils/number';

/* stimulusFetch: 'lazy' */
export default class NumberController extends Controller {
  declare scaleValue: number;
  declare groupingValue: boolean;
  static values = {
    scale: Number,
    grouping: Boolean,
  };

  connect() {
    this.element.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target.value = numberFormater(target?.value, this.scaleValue, this.groupingValue);
    });
  }
}
