import { Controller } from '@hotwired/stimulus';
import { getJson } from '../../utils/api';
import { setHelpText, removeAllErrorMessages, addErrorMessage } from '../../utils/input';
import { numberParser, numberFormater } from '../../utils/number';

interface Share {
  id: number;
  shareholderId: number;
  operatingCompanyId: number;
  value: number;
}

interface CourtAuthorization {
  id: number;
  identifyingName: string;
  value: number;
}

/* stimulusFetch: 'lazy' */
export default class ShareTransferController extends Controller {
  declare additionalSharesPrototypeUrlValue: string;
  declare shareTransferNumberPrototypeUrlValue: string;
  declare courtAuthorizationsPrototypeUrlValue: string;
  static values = {
    additionalSharesPrototypeUrl: String,
    shareTransferNumberPrototypeUrl: String,
    courtAuthorizationsPrototypeUrl: String,
  };

  declare entryTransactionTypeTarget: HTMLInputElement;
  declare sourceShareSumTarget: HTMLInputElement;
  declare courtAuthorizationsTarget: HTMLDivElement;
  declare newShareInfoTarget: HTMLDivElement;
  static targets = ['entryTransactionType', 'sourceShareSum', 'courtAuthorizations', 'newShareInfo'];

  initialSourceShareInfo: null | Share;
  additionalSourceShares: Share[];

  sourceShares: HTMLDivElement | null;

  connect() {
    this.sourceShares = this.element.querySelector('#share_do_transfer_sourceShares');

    // set initial source share if present
    if (window.INITIAL_SOURCE_SHARE_INFO) {
      this.initialSourceShareInfo = window.INITIAL_SOURCE_SHARE_INFO;
      this.getAdditionalShares();
    } else {
      const sourceShare = this.element.querySelector('select[id^=share_do_transfer_sourceShares_]') as HTMLSelectElement;
      if (sourceShare && sourceShare.value !== '') {
        this.getInitialSourceShare(parseInt(sourceShare.value, 10));
      }
    }

    this.setValidEntryTransactionTypes();
  }

  setLimit(limit: string | null = null): void {
    if (limit) {
      this.sourceShares?.setAttribute('data-input--collection-limit-value', limit);
    } else {
      this.sourceShares?.removeAttribute('data-input--collection-limit-value');
    }
  }

  entryTransactionTypeChanged(): void {
    const entryTransactionType = this.entryTransactionTypeTarget.querySelector('input:checked') as HTMLFormElement;
    const entryTransactionTypeValue = entryTransactionType?.value;
    if (entryTransactionTypeValue === 'entry' || entryTransactionTypeValue === 'increase') {
      this.setLimit('0');
    }
  }

  sourceShareAdded(): void {
    const items = this.element.querySelectorAll(
      '#share_do_transfer_sourceShares [data-input--collection-target="item"]',
    );
    if (items.length > 1) {
      this.setInputChoices();
    } else {
      this.setLimit('1');
    }
  }

  sourceShareDeleted(): void {
    const items = this.element.querySelectorAll(
      '#share_do_transfer_sourceShares [data-input--collection-target="item"]',
    );
    if (items && items.length === 0) {
      this.resetSourceShares();
    } else {
      this.setSourceShareSum();
    }
  }

  setValidEntryTransactionTypes() {
    const increaseOption = this.entryTransactionTypeTarget.querySelector('[value="increase"]') as HTMLInputElement;
    const entryOption = this.entryTransactionTypeTarget.querySelector('[value="entry"]') as HTMLInputElement;

    if (this.selectedSourceShares().length === 0) {
      increaseOption?.removeAttribute('disabled');
      entryOption?.removeAttribute('disabled');
    } else {
      increaseOption.disabled = true;
      entryOption.disabled = true;
    }
  }

  selectedSourceShares(): string[] {
    const selectedSourceShares: string[] = [];
    const selects: NodeListOf<HTMLSelectElement> = this.element.querySelectorAll(
      '#share_do_transfer_sourceShares select',
    );
    for (let index = 0; index < selects.length; index += 1) {
      const select: HTMLSelectElement = selects[index];
      if (select.value !== '') {
        selectedSourceShares.push(select.value);
      }
    }
    return selectedSourceShares;
  }

  sourceSharesInputChanged(event: Event): void {
    const target = event.target as HTMLSelectElement;

    // Check if target is the first item
    if (target.closest('.stack')?.querySelector('select[id^=share_do_transfer_sourceShares_]')?.id == target.id) {
      const valueEmpty = target.value === '';
      if (valueEmpty) {
        this.resetSourceShares();
      } else {
        // Limit additional shares until source additional source shares are loaded
        if (this.selectedSourceShares().length === 1) {
          this.setLimit('1');
        }
        this.getInitialSourceShare(parseInt(target.value, 10));
      }
    }

    this.setValidEntryTransactionTypes();
    this.setSourceShareSum();
  }

  resetSourceShares(): void {
    this.initialSourceShareInfo = null;
    this.additionalSourceShares = [];

    // Set the source share sum to 0
    this.setSourceShareSum();

    // Clear additional source shares
    this.setLimit('0');
    this.setLimit('1');

    // Reset transfer number
    const transferNumber = this.element.querySelector('#share_do_transfer_transferNumber') as HTMLInputElement;
    transferNumber.value = '';

    // Reset the messages
    this.setValidEntryTransactionTypes();
    this.getCourtAuthorizations();
  }

  getInitialSourceShare(shareId: number): void {
    const url = this.additionalSharesPrototypeUrlValue.replace(/\/99999999\//, `/${shareId}/`);

    getJson(url)
      .then((data) => {
        this.initialSourceShareInfo = data.find((share) => share.id === shareId);

        this.getCourtAuthorizations();
        this.additionalSourceShares = data;
        this.setLimit(this.additionalSourceShares.length.toString());
        this.setNextFreeShareholderNumber();

        // The source share sum can only be set if the additional shares are loaded
        this.setSourceShareSum();
      })
      .catch((e) => {
        throw new Error(e);
      });
  }

  getAdditionalShares() {
    if (this.initialSourceShareInfo) {
      const url = this.additionalSharesPrototypeUrlValue.replace(/\/99999999\//, `/${this.initialSourceShareInfo.id}/`);
      getJson(url)
        .then((data) => {
          this.additionalSourceShares = data;
          this.setLimit(this.additionalSourceShares.length.toString());
          this.setNextFreeShareholderNumber();

          // The source share sum can only be set if the additional shares are loaded
          this.setSourceShareSum();
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  }

  sourceShareSum(): number {
    let valuesSum = 0;

    this.additionalSourceShares?.forEach((share) => {
      if (this.selectedSourceShares().includes(share.id.toString())) {
        valuesSum += share.value;
      }
    });

    return valuesSum;
  }

  setSourceShareSum(): void {
    const transferValueInput = document.getElementById('share_do_transfer_value') as HTMLInputElement;
    const transferPriceInput = document.getElementById('share_do_transfer_price') as HTMLInputElement;

    const valuesSumString = numberFormater(this.sourceShareSum().toString());

    // Set source share sum
    this.sourceShareSumTarget.innerText = valuesSumString;

    // Set Price
    transferPriceInput.value = valuesSumString;

    // Set value help
    setHelpText(transferValueInput, `Summe der Quellanteile: ${valuesSumString}`);
  }

  setNextFreeShareholderNumber(): void {
    const companyId = this.initialSourceShareInfo
      ? this.initialSourceShareInfo.operatingCompanyId
      : document.getElementById('share_do_transfer_operatingCompany')?.value;
    const transferNumberEl = document.getElementById('share_do_transfer_transferNumber') as HTMLInputElement;

    if (companyId) {
      const url = this.shareTransferNumberPrototypeUrlValue.replace(/\/99999999\//, `/${companyId}/`);
      getJson(url)
        .then((data) => {
          transferNumberEl.value = data;
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  }

  renderCourtAuthorization(message: string, list?: CourtAuthorization[]): void {
    const toastEl = this.courtAuthorizationsTarget.querySelector('.toast') as HTMLDivElement;
    const titleEl = this.courtAuthorizationsTarget.querySelector('.toast .h6') as HTMLHeadingElement;
    const listEl = this.courtAuthorizationsTarget.querySelector('[data-list]') as HTMLDivElement;
    const ulEl = listEl.querySelector('ul') as HTMLUListElement;

    if (message !== '') {
      titleEl.innerText = message;
      toastEl.hidden = false;
    } else {
      toastEl.hidden = true;
    }

    if (list) {
      let html = '';
      list.forEach((item) => {
        const li = `<li class="item py--2xs">${item.identifyingName}</li>`;
        html += li;
      });
      ulEl.innerHTML = html;
      listEl.hidden = false;
    } else {
      listEl.hidden = true;
    }
  }

  // Get the courtAuthorizations
  getCourtAuthorizations(): void {
    const shareholderEl = this.element.querySelector('#share_do_transfer_shareholder') as HTMLInputElement;
    if (shareholderEl) {
      const shareholderId = shareholderEl.value;
      if (this.initialSourceShareInfo && shareholderId && shareholderId !== '') {
        const companyId = this.initialSourceShareInfo.operatingCompanyId as number;
        const url = this.courtAuthorizationsPrototypeUrlValue
          .replace(/\/OC-ID\//, `/${companyId}/`)
          .replace(/\/99999999\//, `/${shareholderId}/`);

        getJson(url)
          .then((data) => {
            if (data.length === 0) {
              this.renderCourtAuthorization('Es liegt keine Vollmacht für die gewählte Person vor!');
            } else {
              this.renderCourtAuthorization('', data);
            }
          })
          .catch((e) => {
            throw new Error(e);
          });
      } else {
        this.renderCourtAuthorization('Wählen Sie zuerst einen Quellanteil und einen neuen Anteilseigner aus.');
      }
    }
  }

  // Set the additionalSourceShares choices to all additionalSourceShares
  setInputChoices() {
    const selectElements: NodeListOf<HTMLSelectElement> = this.element.querySelectorAll(
      '#share_do_transfer_sourceShares select',
    );

    if (selectElements.length > 1) {
      for (let i = 0; i < selectElements.length; i += 1) {
        // Last item but not first item
        if (i !== 0 && i === selectElements.length - 1) {
          this.setReducedInputChoices(selectElements[i]);
        }
      }
    }
  }

  reducedShares(): string[] {
    const reducedShares: string[] = [];

    this.additionalSourceShares?.forEach((share) => {
      if (!this.selectedSourceShares().includes(share.id.toString())) {
        reducedShares.push(share.id.toString());
      }
    });

    return reducedShares;
  }

  setReducedInputChoices(selectElement: HTMLSelectElement): void {
    const reducedShares = this.reducedShares();

    for (let index = selectElement.options.length - 1; index >= 0; index -= 1) {
      const option = selectElement.options[index];

      if (option.value !== '' && !reducedShares.includes(option.value)) {
        selectElement.remove(index);
      }
    }
  }

  // Validate the source values against the destination value
  checkValueInput(): void {
    const valueInput = document.getElementById('share_do_transfer_value') as HTMLInputElement;
    const value = numberParser(valueInput.value);

    const sourceShareSum = this.sourceShareSum();
    const entryTransactionType = this.entryTransactionTypeTarget.querySelector('input:checked') as HTMLFormElement;
    const entryTransactionTypeValue = entryTransactionType?.value;

    // not enough source share value
    if (value > sourceShareSum && (sourceShareSum > 0 || entryTransactionTypeValue !== 'entry')) {
      removeAllErrorMessages(valueInput);
      addErrorMessage(valueInput, 'Zu wenig Quellsumme.');
      this.newShareInfoTarget.hidden = true;
    }

    // the value is valid
    if (value === sourceShareSum) {
      removeAllErrorMessages(valueInput);
      this.newShareInfoTarget.hidden = true;
    }

    // an additional share will be created
    if (value < sourceShareSum) {
      if (this.selectedSourceShares().length > 1) {
        removeAllErrorMessages(valueInput);
        addErrorMessage(
          valueInput,
          `Die Summe der Quellanteile (${numberFormater(sourceShareSum.toString())}€) ist zu hoch für einen Transfer über ${numberFormater(value.toString())}€.
          Bitte erst eine Zusammenführung der betreffenden Quellanteile durchführen.`,
        );
      } else {
        removeAllErrorMessages(valueInput);
        this.newShareInfoTarget.hidden = false;
      }
    }
  }
}
