import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class ReelController extends Controller {
  declare wrapperTarget: HTMLElement;
  declare contentTarget: HTMLElement;
  static targets = ['wrapper', 'content'];
  declare directionValue: string;
  static values = {
    direction: { type: String, default: 'horizontal' },
  };

  connect(): void {
    // touch detection
    window.addEventListener(
      'touchstart',
      function touched() {
        document.body.classList.add('touch');
        window.removeEventListener('touchstart', touched, false);
      },
      false,
    );

    // Set inital value
    this.element.setAttribute('data-overflowing', this.determineOverflow(this.contentTarget, this.wrapperTarget));

    this.wrapperTarget.addEventListener('scroll', () => {
      this.element.setAttribute('data-overflowing', this.determineOverflow(this.contentTarget, this.wrapperTarget));
    });

    if ('ResizeObserver' in window) {
      new ResizeObserver(() => {
        this.element.setAttribute('data-overflowing', this.determineOverflow(this.contentTarget, this.wrapperTarget));
      }).observe(this.contentTarget);
    }
  }

  determineOverflow(content: HTMLElement, container: HTMLElement): string {
    const containerMetrics = container.getBoundingClientRect();

    const containerMetricsRight = Math.floor(
      this.directionValue === 'horizontal' ? containerMetrics.right : containerMetrics.bottom,
    );
    const containerMetricsLeft = Math.floor(
      this.directionValue === 'horizontal' ? containerMetrics.left : containerMetrics.top,
    );
    const contentMetrics = content.getBoundingClientRect();
    const contentMetricsRight = Math.floor(
      this.directionValue === 'horizontal' ? contentMetrics.right : contentMetrics.bottom,
    );
    const contentMetricsLeft = Math.floor(
      this.directionValue === 'horizontal' ? contentMetrics.left : contentMetrics.top,
    );
    if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
      return 'both';
    }
    if (contentMetricsLeft < containerMetricsLeft) {
      return 'start';
    }
    if (contentMetricsRight > containerMetricsRight) {
      return 'end';
    }
    return 'none';
  }
}
