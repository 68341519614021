import { Controller } from '@hotwired/stimulus';
import { getJson } from '../../utils/api';
import {
  getNextSelectableOption,
  getPreviousSelectableOption,
  getFirstSelectableOption,
  getLastSelectableOption,
} from '../../utils/select';
import { isScrollable, maintainScrollVisibility } from '../../utils/scroll';
import { anchorPositioning } from '../../utils/dropdown';

interface SelectOption {
  value: string;
  text: string;
}

export default class SuggestionsController extends Controller<HTMLInputElement> {
  declare urlValue: string;
  declare valuesValue: Array<string>;
  declare minCharsValue: number;
  static values = {
    url: String,
    values: Array,
    minChars: Number,
  };

  declare textBoxEl: HTMLInputElement;
  declare wrapperEl: HTMLDivElement;
  declare container: HTMLDivElement;
  declare listboxEl: HTMLUListElement;
  declare statusEl: HTMLDivElement;

  declare activeOptionId: null | string;
  declare open: boolean;
  declare baseId: string;
  declare suggestions: string[] | SelectOption[];

  connect() {
    this.open = false;
    this.baseId = this.element.id;

    this.textBoxEl = this.element;
    this.container = this.textBoxEl.parentElement as HTMLDivElement;
    this.container.classList.add('dropdown-trigger', 'dropdown-trigger--click');
    this.wrapperEl = document.createElement('div');
    this.wrapperEl.classList.add('dropdown');
    this.wrapperEl.dataset.size = 'fluid';
    this.wrapperEl.dataset.length = '7';
    this.container.appendChild(this.wrapperEl);

    this.enhanceTextBox();
    this.createMenu();
    anchorPositioning(this.textBoxEl, this.wrapperEl);
    this.createStatusBox();

    document.addEventListener('click', (event) => this.onDocumentClick(event));
  }

  enhanceTextBox() {
    this.textBoxEl.setAttribute('role', 'combobox');
    this.textBoxEl.setAttribute('aria-controls', `listbox-${this.baseId}`);
    this.textBoxEl.setAttribute('aria-expanded', 'false');
    this.textBoxEl.setAttribute('aria-haspopup', 'listbox');
    this.textBoxEl.setAttribute('aria-activedescendant', '');
    this.textBoxEl.setAttribute('aria-autocomplete', 'list');
    this.textBoxEl.setAttribute('autocomplete', 'off');
    this.textBoxEl.setAttribute('autocapitalize', 'none');

    this.textBoxEl.addEventListener('click', (event) => this.onTextBoxClick(event));

    this.textBoxEl.addEventListener('keydown', (event) => this.onTextBoxKeyDown(event));
    this.textBoxEl.addEventListener('keyup', (event) => this.onTextBoxKeyUp(event));
  }

  createMenu() {
    this.listboxEl = document.createElement('ul');
    this.listboxEl.setAttribute('id', `listbox-${this.baseId}`);
    this.listboxEl.setAttribute('role', 'listbox');
    this.listboxEl.classList.add('listbox', 'listbox--vertical');
    this.wrapperEl.appendChild(this.listboxEl);
  }

  createStatusBox() {
    this.statusEl = document.createElement('div');
    this.statusEl.setAttribute('aria-live', 'polite');
    this.statusEl.setAttribute('role', 'status');
    this.statusEl.classList.add('assistive-text');
    this.container.appendChild(this.statusEl);
  }

  onDocumentClick(event: MouseEvent) {
    if (this.container !== event.target && !this.container.contains(event.target as Node)) {
      this.updateMenuState(false, false);
    }
  }

  onTextBoxKeyDown(event: KeyboardEvent) {
    const first = getFirstSelectableOption(this.listboxEl);
    const last = getLastSelectableOption(this.listboxEl);

    switch (event.key) {
      case 'Tab':
        this.updateMenuState(false, false);
        break;
      case 'Home':
        if (this.open && first) {
          event.preventDefault();
          this.highlightOption(first);
        }
        break;
      case 'ArrowDown':
        this.onTextBoxDownArrow(event);
        break;
      case 'ArrowUp':
        this.onTextBoxUpArrow(event);
        break;
      case 'End':
        if (this.open && last) {
          event.preventDefault();
          this.highlightOption(last);
        }
        break;
      case 'Enter':
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  onTextBoxKeyUp(event: KeyboardEvent): void {
    switch (event.key) {
      // ignore these keys otherwise
      // the menu will show briefly
      case 'ArrowLeft':
      case 'ArrowRight':
      case ' ':
      case 'Shift':
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      case 'Enter':
        this.onTextBoxEnter(event);
        break;
      case 'Escape':
        this.updateMenuState(false);
        break;
      default:
        this.onTextBoxType();
        break;
    }
  }

  onTextBoxClick(event: MouseEvent) {
    this.clearOptions();
    // this.buildMenu(this.suggestions);
    // this.updateStatus(this.suggestions.length);
    // this.updateMenuState(true);
    this.onTextBoxType();
    if (typeof event.currentTarget.select === 'function') {
      event.currentTarget.select();
    }
  }

  onTextBoxDownArrow(event: KeyboardEvent): void {
    event.preventDefault();

    if (this.open) {
      const nextOption = this.activeOptionId
        ? getNextSelectableOption(document.getElementById(this.activeOptionId) as HTMLLIElement)
        : getFirstSelectableOption(this.listboxEl);
      if (nextOption) {
        this.highlightOption(nextOption);
      }
    } else {
      this.updateMenuState(true, false);
    }
  }

  onTextBoxUpArrow(event: KeyboardEvent): void {
    event.preventDefault();

    if (this.activeOptionId) {
      const previousOption = this.activeOptionId
        ? getPreviousSelectableOption(document.getElementById(this.activeOptionId) as HTMLLIElement)
        : null;
      if (previousOption) {
        this.highlightOption(previousOption);
      } else {
        // Close menu
        this.updateMenuState(false, true);
      }
    }
  }

  onTextBoxEnter(event: KeyboardEvent): void {
    if (this.open) {
      event.preventDefault();
      const activeOption = this.activeOptionId ? (document.getElementById(this.activeOptionId) as HTMLLIElement) : null;
      if (activeOption) {
        this.selectOption(activeOption);
        this.updateMenuState(false);
      }
    } else {
      this.updateMenuState(true);
    }
  }

  onTextBoxType() {
    if (!this.urlValue && this.valuesValue.length > 0 && this.textBoxEl.value.trim().length >= this.minCharsValue) {
      this.suggestions = this.valuesValue.filter((v) => v.includes(this.textBoxEl.value));
      this.buildMenu(this.suggestions);
      this.updateMenuState(true);
      this.updateStatus(this.suggestions.length);
    } else if (this.urlValue.length > 0 && this.textBoxEl.value.trim().length >= this.minCharsValue) {
      const params = this.textBoxEl.value.length > 0 ? { q: this.textBoxEl.value } : {};
      getJson(this.urlValue, params).then((response) => {
        this.suggestions = response;
        // @TODO: Better error handling
        this.buildMenu(this.suggestions);
        this.updateMenuState(true);
        this.updateStatus(this.suggestions.length);
      });
    } else {
      this.updateMenuState(false);
    }
  }

  selectActiveOption(): void {
    const option = this.getActiveOption();
    if (option) {
      this.selectOption(option);
    }
  }

  selectOption(option: HTMLLIElement): void {
    if (option.getAttribute('aria-disabled') !== 'true') {
      const value = option.innerText;
      if (value) {
        this.setValue(value);
      }
      this.updateMenuState(false, true);
    }
  }

  isOptionSelected(): string | null {
    return this.activeOptionId;
  }

  getActiveOption(): HTMLLIElement | null {
    return document.getElementById(this.activeOptionId);
  }

  highlightOption(optionEl: HTMLLIElement) {
    if (this.activeOptionId) {
      const activeOption = document.getElementById(this.activeOptionId);
      activeOption?.setAttribute('aria-selected', 'false');
    }

    this.textBoxEl.setAttribute('aria-activedescendant', optionEl.id);

    optionEl.setAttribute('aria-selected', 'true');

    if (this.open && isScrollable(this.listboxEl)) {
      maintainScrollVisibility(optionEl, this.listboxEl);
    }
    this.activeOptionId = optionEl.id;
  }

  clearOptions() {
    while (this.listboxEl.firstChild) {
      this.listboxEl.removeChild(this.listboxEl.firstChild);
    }
  }

  buildMenu(options: string[] | SelectOption[]): void {
    this.clearOptions();
    this.activeOptionId = null;

    if (options.length) {
      for (let i = 0; i < options.length; i++) {
        this.listboxEl.append(this.getOptionHtml(i, options[i]));
      }
    } else {
      this.listboxEl.append(this.getNoResultsOptionHtml());
    }
    this.listboxEl.scrollTop = 0;
  }

  getNoResultsOptionHtml(): HTMLLIElement {
    const el = document.createElement('li');
    el.classList.add('dropdown__item');
    // @TODO: Add default option
    el.innerHTML = `<span class="dropdown__option">${'Kein Ergebnis'}</span>`;
    return el;
  }

  getOptionHtml(i: number, option: string | SelectOption): HTMLLIElement {
    const optionEl = document.createElement('li');
    optionEl.setAttribute('role', 'option');
    optionEl.classList.add('listbox__option', 'listbox__option--plain');
    optionEl.setAttribute('aria-selected', 'false');
    optionEl.id = `${this.baseId}--${i + 1}`;
    if (typeof option === 'string') {
      optionEl.innerText = option;
    } else {
      optionEl.setAttribute('data-option-value', option.value);
      optionEl.innerText = option.text;
    }
    optionEl.addEventListener('click', () => this.selectOption(optionEl));
    return optionEl;
  }

  updateStatus(resultCount: number) {
    // @TODO: Add default option
    if (resultCount === 0) {
      this.statusEl.textContent = 'Kein Ergebnis';
    } else {
      this.statusEl.textContent = `${resultCount} Einträge verfügbar.`;
    }
  }

  setValue(val: string) {
    this.textBoxEl.value = val.trim();
    this.textBoxEl.dispatchEvent(new Event('change'));
  }

  updateMenuState(open: boolean, callFocus = true) {
    this.open = open;

    if (open) {
      this.container.classList.add('is-open');
    } else {
      this.container.classList.remove('is-open');
    }
    this.textBoxEl?.setAttribute('aria-expanded', `${open}`);

    if (callFocus) {
      this.textBoxEl.focus();
    }
  }
}
