const numberSanitizer = function (value: string): string {
  if (value === '') {
    return '';
  }
  // don't touch a zero at all
  if (value.toString().match(/^-?0+$/)) {
    return '0';
  }

  // Must be in correct order to be safe for the regex or must be escaped
  const validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '-'];
  const isNegative = value.toString().charAt(0) === '-';
  const parts = value
    .toString()
    .replace(new RegExp(`[^${validChars.join()}]`, 'gi'), '') // remove all but the validChars
    .replace(/([-.]|^0+|,+$)/g, '') // remove leading 0, tailing , and all - and .
    .replace(/^,/g, '0,') // replace a leading , with 0,
    .split(','); // and split by , (we only look for the first two parts afterwards, so more that the number will be cut off at the second ,

  if (isNegative) {
    parts[0] = `-${parts[0]}`; // readd the negative sign
  }

  return parts[1] ? parts.slice(0, 2).join(',') : parts[0];
};

const numberParser = (val: string): number => parseFloat(numberSanitizer(val).replace(',', '.'));

const numberFormater = (number: string, scale = 2, grouping = true): string => {
  if (number === '') {
    return '';
  }

  const numberAsFloat = parseFloat(numberSanitizer(number).replace(',', '.'));
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: scale,
    maximumFractionDigits: scale,
    useGrouping: grouping,
  };

  return Number.isNaN(numberAsFloat) ? number : new Intl.NumberFormat('de-DE', options).format(numberAsFloat);
};

export { numberParser, numberSanitizer, numberFormater };
