import { Controller } from '@hotwired/stimulus';
import deLocaleObject from 'date-fns/locale/de';
import { LineChart } from '@carbon/charts';
import { ChartTabularData, LineChartOptions } from '@carbon/charts/interfaces';

/* stimulusFetch: 'lazy' */
export default class LineChartController extends Controller {
  declare dataValue: ChartTabularData;
  declare optionsValue: LineChartOptions;
  static values = {
    data: Array,
    options: Object,
  };

  chart: LineChart;

  connect() {
    this.renderChart();
  }

  disconnect() {
    this.element.innerHTML = '';
  }

  renderChart() {
    const options = this.optionsValue;
    if (options.timeScale) {
      options.timeScale.localeObject = deLocaleObject;
    }

    this.chart = new LineChart(this.element, {
      data: this.dataValue,
      options,
    });
  }
}
