import * as Turbo from '@hotwired/turbo';
import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class TabsController extends Controller {
  declare closableValue: boolean;
  declare urlUpdateValue: boolean;
  static values = {
    closable: {
      type: Boolean,
      default: false,
    },
    urlUpdate: {
      type: Boolean,
      default: false,
    },
  };

  tabItems: NodeListOf<HTMLButtonElement>;

  connect() {
    this.tabItems = this.element.querySelectorAll('[role="tab"]');

    this.tabItems.forEach((tabItem) => {
      const targetId = tabItem.getAttribute('aria-controls');

      if (targetId) {
        tabItem.addEventListener('click', () => this.toggleTabs(targetId));
      }
    });

    // Select first Tab if no Tab is selected
    if (!this.element.querySelector('[aria-selected]')) {
      const firstTab = this.tabItems[0]?.getAttribute('aria-controls');
      if (firstTab) {
        this.toggleTabs(firstTab);
      }
    }
  }

  toggleTabs(target: string) {
    this.tabItems.forEach((tabItem) => {
      const targetEl = document.querySelector(`#${tabItem.getAttribute('aria-controls')}`) as HTMLElement | null;

      if (this.urlUpdateValue) {
        const params = new URLSearchParams(window.location.search);
        const targetId = target.replace('panel-', '');
        if (targetId !== params.get('tab')) {
          params.set('tab', targetId);

          Turbo.setProgressBarDelay(200); // @todo @florianboe only needed because of a bug in Turbo https://github.com/hotwired/turbo/issues/1202

          Turbo.visit(`${window.location.pathname}?${params.toString()}`);
        }
      } else if (targetEl && target === targetEl.id) {
        if (this.closableValue && tabItem.getAttribute('aria-selected') === 'true') {
          TabsController.deactivate(tabItem, targetEl);
        } else {
          TabsController.activate(tabItem, targetEl);
        }
      } else if (targetEl) {
        TabsController.deactivate(tabItem, targetEl);
      } else {
        console.warn(`No proper link element or no target element was found for #${tabItem?.id}.`);
      }
    });
  }

  static activate(linkEl: HTMLButtonElement, targetEl: HTMLElement) {
    linkEl.setAttribute('aria-selected', 'true');
    linkEl.setAttribute('tabindex', '-1');
    targetEl.removeAttribute('hidden');
  }

  static deactivate(linkEl: HTMLButtonElement, targetEl: HTMLElement) {
    linkEl.removeAttribute('aria-selected');
    linkEl.setAttribute('tabindex', '0');

    targetEl.setAttribute('hidden', 'true');
  }
}
