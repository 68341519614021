import L, { LatLngTuple } from 'leaflet';
import { getJson } from '../../utils/api';
import MapController, { FACILITY_ICON, FACILITY_ICON_OTHER } from './base_controller';

/* stimulusFetch: 'lazy' */
export default class MapFacilityController extends MapController {
  declare facilityUrlValue: string | undefined;
  declare locationValue: LatLngTuple;
  static values = {
    facilityUrl: String,
    location: Array,
  };

  initialIndicator: L.Marker | null = null;
  facilities = [];

  connect() {
    super.connect();
    if (this.map) {
      this.initialIndicator = L.marker(this.locationValue, {
        icon: FACILITY_ICON,
      }).addTo(this.map);
      this.map.fitBounds([this.locationValue]);
    }

    this.mapTarget.addEventListener('mouseenter', () => {
      if (this.facilities.length === 0) {
        this.getFacilities();
      }
    });
  }

  getFacilities() {
    if (this.facilityUrlValue && this.map && this.facilities.length === 0) {
      getJson(this.facilityUrlValue)
        .then((facilities: Array<>) => {
          this.facilities = facilities;

          const currentYear = new Date().getFullYear();

          facilities.forEach((facility) => {
            if (
              (facility.initialStartupAt ? facility.initialStartupAt.slice(0, 4) <= currentYear : false)
              && (facility.finalDecomissioningAt === null || currentYear < facility.finalDecomissioningAt.slice(0, 4))
              && facility.location
            ) {
              // Remove old marker
              this.map?.removeLayer(this.initialIndicator);

              let marker = null;

              // Add new marker for current facility
              if (JSON.stringify(facility.location) === JSON.stringify(this.locationValue)) {
                marker = L.marker(facility.location, {
                  icon: FACILITY_ICON,
                }).addTo(this.map);
              } else {
                // Add marker for other facilities
                marker = L.marker(facility.location, {
                  icon: FACILITY_ICON_OTHER,
                }).addTo(this.map);
              }

              if (marker) {
                marker.bindTooltip(facility.displayName);
              }
            }
          });
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  }

  bounds(facilities) {
    const bounds = [];

    facilities.forEach((facility) => {
      if (facility.location !== null) {
        bounds.push(facility.location);
      }
    });

    return bounds;
  }
}
