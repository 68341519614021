import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class InputSuggestions extends Controller {
  declare inputTarget: HTMLInputElement;
  declare optionTargets: HTMLElement[];
  static targets = ['input', 'option'];

  connect(): void {
    this.optionTargets.forEach((target) => {
      target.addEventListener('click', () => {
        this.setInputValuesToPlaceholders(target.innerText);
        this.inputTarget.focus();
      });
    });
  }

  setInputValuesToPlaceholders(text: string) {
    let inputText = text;
    const placeholders = inputText.match(/[^{}]+(?=})/g) || [];
    if (placeholders.length > 0) {
      placeholders.forEach((placeholder) => {
        const input = document.querySelector(
          `:is(input, textarea, select)[id$=${placeholder}`,
        ) as HTMLInputElement | null;
        if (input) {
          inputText = inputText.replaceAll(`{${placeholder}}`, input.value);
        } else {
          console.warn(`InputSuggestions: No input found for placeholder {${placeholder}}`);
        }
      });
    }
    this.inputTarget.value = inputText;
  }
}
