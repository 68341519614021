import { makeJsonUrl, mergeQuery } from './url';
import { createFlashMessage } from './flash-message';

/**
 * Return json if response headers is application/json and check if response is ok
 */
export const handleJSONResponse = (response: Response) => response.json().then((json) => {
  if (response.ok) {
    return json;
  }

  return Promise.reject(json);
});

/**
 * Response text if response headers include text/html and if response is ok
 */
export const handleTextResponse = (response: Response) => response.text().then((text) => {
  if (response.ok) {
    return text;
  }

  return Promise.reject(new Error(text));
});

/**
 * General way to handle the server response
 * @param  {Object} response The server response
 * @return {Object}          JSON data
 */
export const handleResponse = (response: Response) => {
  const contentType = response.headers.get('content-type');

  if (contentType?.includes('application/json')) {
    return handleJSONResponse(response);
  }
  if (contentType?.includes('text/plain') || contentType?.includes('text/html')) {
    return handleTextResponse(response);
  }

  // Other response types as necessary. I haven't found a need for them yet though.
  throw new Error(`Sorry, content-type ${contentType} not supported`);
};

/**
 * Catch error if response header is not supported
 */
export const handleError = (error) => {
  if (error.redirected) {
    window.location.replace(error.url);
  }

  createFlashMessage('error', error.title, `<p>${error.detail}</p>`);
  // console.error(`${error.title}: ${error.detail}`);

  return Promise.reject(error);
};

/**
 * Simple PUT action
 * @param  {String} url    The request URL
 * @param  {Object} params Parameters for the request
 * @param  {Object} data   Data for the request body
 * @return {Promise}       Fetch promise
 */
export const put = (url: string, params = {}, data = {}) => fetch(mergeQuery(url, params), {
  method: 'PUT',
  credentials: 'include',
  body: data ? JSON.stringify(data) : JSON.stringify({}),
})
  .then((response) => handleResponse(response))
  .catch((error) => handleError(error));

export const patch = (url: string, params = {}, data = {}) => fetch(mergeQuery(url, params), {
  method: 'PATCH',
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
  credentials: 'include',
  body: data ? JSON.stringify(data) : JSON.stringify({}),
})
  .then((response) => handleResponse(response))
  .catch((error) => handleError(error));

export const post = (url: string, params = {}, data = {}) => fetch(mergeQuery(url, params), {
  method: 'POST',
  credentials: 'include',
  body: data ? JSON.stringify(data) : JSON.stringify({}),
})
  .then((response) => handleResponse(response))
  .catch((error) => handleError(error));

export const restDelete = (url: string, params = {}) => fetch(mergeQuery(url, params), {
  method: 'DELETE',
  credentials: 'include',
})
  .then((response) => handleResponse(response))
  .catch((error) => handleError(error));

/**
 * Get JSON from the server
 * @param  {String} url    The request URL
 * @param  {Object} params Parameters for the request
 * @return {Promise}       Fetch promise
 */
export const getJson = (url: string, params = {}) => fetch(mergeQuery(makeJsonUrl(url), params), {
  method: 'GET',
  credentials: 'include',
})
  .then((data) => handleResponse(data))
  .catch((error) => handleError(error));

/**
 * Post JSON to the server
 * @param  {String} url    The request URL
 * @param  {Object} params Parameters for the request
 * @param  {Object} data   Data for the request body
 * @return {Promise}       Fetch promise
 */
export const postJson = (url: string, params: object = {}, data: object = {}) => fetch(mergeQuery(makeJsonUrl(url), params), {
  method: 'POST',
  credentials: 'include',
  headers: new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }),
  body: JSON.stringify(data),
})
  .then((response) => handleResponse(response))
  .catch((error) => handleError(error));

/**
 * Put JSON to the server
 */
export const putJson = (url: string, params: object = {}, data: object = {}) => fetch(mergeQuery(makeJsonUrl(url), params), {
  method: 'PUT',
  credentials: 'include',
  headers: new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }),
  body: data ? JSON.stringify(data) : JSON.stringify({}),
})
  .then((response) => handleResponse(response))
  .catch((error) => handleError(error));

/**
 * Patch JSON to the server
 */
export const patchJson = (url: string, params: object = {}, data: object = {}) => fetch(mergeQuery(makeJsonUrl(url), params), {
  method: 'PATCH',
  credentials: 'include',
  headers: new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/merge-patch+json',
  }),
  body: JSON.stringify(data),
})
  .then((response) => handleResponse(response))
  .catch((error) => handleError(error));

/**
 * Send delete request
 */
export const deleteJson = (url: string, params = {}, data = {}) => fetch(mergeQuery(makeJsonUrl(url), params), {
  method: 'DELETE',
  credentials: 'include',
  headers: new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }),
  body: JSON.stringify(data),
})
  .then((response) => handleResponse(response))
  .catch((error) => handleError(error));
