import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class ModalController extends Controller<HTMLDialogElement> {
  declare closeTarget: HTMLElement;
  declare hasPrimaryButtonTarget: boolean;
  declare primaryButtonTarget: HTMLButtonElement;
  declare hasSecondaryButtonTarget: boolean;
  declare secondaryButtonTarget: HTMLButtonElement;
  static targets = ['close', 'primaryButton', 'secondaryButton'];

  declare hasAutoHideOffValue: boolean;
  static values = { autoHideOff: Boolean };

  scrollable: boolean;

  connect() {
    // Setup open
    if (this.element.id) {
      const triggers = document.querySelectorAll(`[data-modal="${this.element.id}"]`);
      triggers.forEach((trigger) => trigger.addEventListener('click', (event) => {
        event.preventDefault();
        this.show();
      }));
    } else {
      console.warn('MODAL: Modals need an id to be referenced!');
    }

    this.element.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        this.onEsc();
      }
    });
    this.element.addEventListener('click', (event) => {
      this.onExternalClick(event);
    });
  }

  onShown() {
    // check to see if content scrollable
    const content = this.element.querySelector('modal__content');
    if (content) {
      this.scrollable = content.scrollHeight > content.clientHeight;
    }

    this.element.removeEventListener('transitionend', () => {
      this.onShown();
    });
  }

  onExternalClick(event: MouseEvent) {
    if (event.target === this.element) {
      this.maybeHide();
    }
  }

  onEsc() {
    this.maybeHide();
  }

  onClose() {
    this.maybeHide();
  }

  show() {
    this.element.addEventListener('transitionend', () => this.onShown());
    this.element.showModal();
  }

  maybeHide() {
    if (!this.hasAutoHideOffValue) {
      this.hide();
    }
  }

  hide() {
    if (this.element.hasAttribute('open')) {
      this.element.addEventListener('transitionend', (event) => {
        this.afterHide(event);
      });
    }
    this.element.close();
  }

  afterHide(event) {
    if (event.propertyName === 'opacity') {
      this.element.removeEventListener('transitionend', () => this.afterHide(event));
    }
  }
}
