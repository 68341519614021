import { Controller } from '@hotwired/stimulus';
import deLocaleObject from 'date-fns/locale/de';
import { AreaChart } from '@carbon/charts';
import { AreaChartOptions, ChartTabularData } from '@carbon/charts/interfaces';

/* stimulusFetch: 'lazy' */
export default class AreaChartController extends Controller {
  declare dataValue: ChartTabularData;
  declare optionsValue: AreaChartOptions;
  static values = {
    data: Array,
    options: Object,
  };

  chart: AreaChart;

  connect() {
    this.renderChart();
  }

  disconnect() {
    this.element.innerHTML = '';
  }

  renderChart() {
    const options = this.optionsValue;
    if (options.timeScale) {
      options.timeScale.localeObject = deLocaleObject;
    }

    this.chart = new AreaChart(this.element, {
      data: this.dataValue,
      options,
    });
  }
}
