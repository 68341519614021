import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class ContentSwitcherController extends Controller {
  buttons: NodeListOf<HTMLButtonElement>;
  activeButton: HTMLButtonElement | null;

  connect() {
    this.buttons = this.element.querySelectorAll('button');

    this.buttons.forEach((btn) => {
      btn.addEventListener('click', () => {
        this.togglePanel(btn);
      });
    });
  }

  getTarget(btn: HTMLButtonElement) {
    const targetId = btn.getAttribute('aria-controls');
    return targetId ? document.getElementById(targetId) : null;
  }

  togglePanel(button: HTMLButtonElement) {
    this.buttons.forEach((btn) => {
      const isSelected = btn.getAttribute('aria-selected') === 'true';
      const target = this.getTarget(btn);

      if (!target) {
        console.warn('CONTENT-SWITCHER: No target element could be found!');
      } else {
        // Activate new Panel
        if (btn === button && !isSelected) {
          btn.setAttribute('aria-selected', 'true');
          target.hidden = false;
        } else if (btn !== button) {
          btn.setAttribute('aria-selected', 'false');
          target.hidden = true;
        }
      }
    });
  }
}
