import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class DateRangeController extends Controller {
  clearInputs(): void {
    this.element.querySelectorAll('input').forEach((input) => {
      input.value = '';
    });
  }
}
