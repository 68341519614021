// check if an element is currently scrollable
const isScrollable = function (element: HTMLElement) {
  return element && element.clientHeight < element.scrollHeight;
};

// ensure given child element is within the parent's visible scroll area
const maintainScrollVisibility = function (activeElement: HTMLElement, scrollParent: HTMLElement) {
  const { offsetHeight, offsetTop } = activeElement;
  const { offsetHeight: parentOffsetHeight, scrollTop } = scrollParent;

  const isAbove = offsetTop < scrollTop;
  const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;

  if (isAbove) {
    scrollParent.scrollTo(0, offsetTop);
  } else if (isBelow) {
    scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
  }
};

export { isScrollable, maintainScrollVisibility };
