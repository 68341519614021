import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class SelfserviceUploadController extends Controller {
  declare hasContainerTarget: boolean;
  declare containerTarget: HTMLElement;
  declare hasErrorMessageTarget: boolean;
  declare errorMessageTarget: HTMLElement;
  declare hasSuccessMessageTarget: boolean;
  declare successMessageTarget: HTMLElement;
  static targets = ['container', 'errorMessage', 'successMessage'];

  declare hasUrlValue: boolean;
  declare urlValue: string;
  static values = { url: String };

  async delete() {
    if (this.hasUrlValue) {
      try {
        const response = await fetch(this.urlValue, {
          method: 'DELETE',
          credentials: 'include',
        });
        if (response.ok && response.status === 204) {
          // Delete content container
          if (this.hasContainerTarget) {
            this.containerTarget.remove();
          }
          // Show message
          if (this.hasSuccessMessageTarget) {
            this.successMessageTarget.hidden = false;
          }
        } else if (this.hasErrorMessageTarget) {
          this.errorMessageTarget.hidden = false;
        }
      } catch (error) {
        console.warn(error);
      }
    }

    return Promise.resolve();
  }
}
