import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class BulkactionsController extends Controller {
  declare checkboxAllTarget: HTMLInputElement;
  declare rowTargets: HTMLElement[];
  declare hasCheckboxRowTargets: boolean;
  declare checkboxRowTargets: HTMLInputElement[];
  declare formTarget: HTMLElement;
  static targets = ['checkboxAll', 'checkboxRow', 'row', 'form'];

  connect(): void {
    if (this.hasCheckboxRowTargets) {
      this.rowTargets.forEach((row: HTMLElement) => {
        row.addEventListener('click', (event) => {
          const target = event.target as HTMLElement;
          if (target.localName === 'th' || target.localName === 'td') {
            const checkbox = row.querySelector('input[type="checkbox"]') as HTMLInputElement | null;
            if (checkbox) {
              checkbox.checked = !checkbox.checked;
            }
            this.select();
          }
        });
      });

      // Set initial state
      // Needs a timemout to have the correct effect
      setTimeout(() => {
        this.select();
      }, 200);
    }
  }

  select() {
    const selectedInputs = this.checkboxRowTargets.filter((input: HTMLInputElement) => input.checked);

    if (selectedInputs.length === 0) {
      this.checkboxAllTarget.indeterminate = false;
      this.checkboxAllTarget.checked = false;
    } else if (this.checkboxRowTargets.length !== selectedInputs.length) {
      this.checkboxAllTarget.indeterminate = true;
    } else {
      this.checkboxAllTarget.indeterminate = false;
      this.checkboxAllTarget.checked = true;
    }
  }

  selectAll() {
    this.checkboxRowTargets.forEach((input: HTMLInputElement) => {
      input.checked = this.checkboxAllTarget.indeterminate || this.checkboxAllTarget.checked;
    });
  }
}
