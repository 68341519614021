import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class PasswordSugestionController extends Controller {
  declare readonly previewTarget: HTMLElement;
  static targets = ['preview', 'input'];

  declare static urlValue: string;
  declare static inputValue: string;
  static values = { url: String, input: String };

  urlValue: string;
  inputValue: string;

  getPassword(event: PointerEvent) {
    event.preventDefault();

    fetch(this.urlValue).then((response) => {
      response.text().then((text) => {
        this.previewTarget.innerHTML = text;
      });
    });
  }

  setPassword(event: PointerEvent) {
    event.preventDefault();

    const input = document.getElementById(this.inputValue) as HTMLInputElement | null;
    if (input) {
      input.value = this.previewTarget.innerHTML;
    }
  }
}
