import { Controller } from '@hotwired/stimulus';
import { v4 as uuidv4 } from 'uuid';
import { customEvent } from '../../utils/events';

/* stimulusFetch: 'lazy' */
export default class CollectionController extends Controller {
  declare groupTarget: HTMLElement;
  declare itemTargets: HTMLDivElement[];
  declare addTarget: HTMLButtonElement;
  declare emptyTarget: HTMLDivElement;
  declare prototypeTarget: HTMLTemplateElement;
  static targets = ['group', 'item', 'empty', 'add', 'prototype'];

  declare limitValue: number | null;
  declare readonly hasLimitValue: boolean;
  static values = {
    limit: Number,
  };

  initialize(): void {
    if (this.prototypeTarget) {
      this.toggleAddButton();
    } else {
      console.warn('No prototype template given.');
    }
  }

  limitValueChanged(value: number | null) {
    this.toggleAddButton();

    // Prevent reduction because of undifined limit value
    if (this.hasLimitValue) {
      if (value !== null && value <= this.itemTargets.length) {
        this.reduceItemsLength(value);
      }
    }

    this.groupTarget.dispatchEvent(
      customEvent('collection:limit', {
        limit: value,
      }),
    );
  }

  addItem(event: PointerEvent) {
    event.preventDefault();

    if (this.prototypeTarget) {
      const clone = this.prototypeTarget.content.cloneNode(true) as HTMLElement;

      const item = clone.querySelector('[data-input--collection-target="item"]') as HTMLElement | null;
      if (item) {
        this.setItemAttributes(item, uuidv4(), '__name__');

        // Insert before button
        this.groupTarget.append(clone);

        // Select first active input element
        const inputs = this.itemTargets[this.itemTargets.length - 1].querySelectorAll(
          ':is(input, textarea, select):not([aria-hidden="true"]):not([disabled])',
        ) as NodeListOf<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
        if (inputs.length > 0) {
          inputs[0].focus();
        }

        this.toggleEmptyMessage();
        this.toggleAddButton();

        // Dispatch custom event
        this.groupTarget.dispatchEvent(customEvent('collection:add'));
      } else {
        console.warn('No wrapper collection-target in template.');
      }
    }
  }

  deleteItem(event: PointerEvent) {
    const target = event.target as HTMLElement;
    const item = target.closest('[data-input--collection-target="item"]');

    event.preventDefault();

    if (target && item) {
      item.parentNode?.removeChild(item);

      this.toggleEmptyMessage();
      this.toggleAddButton();

      // Dispatch custom event
      this.groupTarget.dispatchEvent(customEvent('collection:deleted'));
    } else {
      console.warn('No [data-input--collection-target="item"] found.');
    }
  }

  reduceItemsLength(limit: number) {
    this.itemTargets.forEach((item: HTMLDivElement, index: number) => {
      if (index >= limit) {
        item.parentNode?.removeChild(item);
      }
    });
  }

  setItemAttributes(item: HTMLElement, replaceValue: string, searchValue: string) {
    const collectionName = this.element.id as string;
    const searchAfter = collectionName.split('_');

    const idRegex = `(${searchAfter[searchAfter.length - 1]}_)(${searchValue})`;
    const nameRegex = `(${searchAfter[searchAfter.length - 1]}\\]\\[)(${searchValue})`;

    item.setAttribute('id', item.id.replace(new RegExp(idRegex), `$1${replaceValue}`));

    const formElements = item.querySelectorAll('label, input, select, textarea, div.form-element__error') as NodeListOf<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLLabelElement | HTMLDivElement
    >;
    for (let i = 0; i < formElements.length; i += 1) {
      const element = formElements[i];
      if (element.id) {
        element.setAttribute('id', element.id.replace(new RegExp(idRegex), `$1${replaceValue}`));
      }
      if (element.hasAttribute('name')) {
        element.setAttribute('name', element.name.replace(new RegExp(nameRegex), `$1${replaceValue}`));
      }
      if (element.hasAttribute('for')) {
        element.setAttribute('for', element.htmlFor.replace(new RegExp(idRegex), `$1${replaceValue}`));
      }
    }
  }

  toggleEmptyMessage(): void {
    this.emptyTarget.hidden = this.itemTargets.length !== 0;
  }

  toggleAddButton(): void {
    if (this.limitValue ? this.itemTargets.length < this.limitValue : true) {
      this.addTarget.hidden = false;
    } else {
      this.addTarget.hidden = true;
    }
  }
}
