import { Controller } from '@hotwired/stimulus';
import Fuse, { FuseResult } from 'fuse.js';
import { anchorPositioning } from '../../utils/dropdown';

import {
  highlightResult,
  getSelectOptions,
  getSelectOptionByValue,
  getSelectOptionByText,
  clearOptions,
  getNextSelectableOption,
  getPreviousSelectableOption,
  getFirstSelectableOption,
  getLastSelectableOption,
} from '../../utils/select';

import { isScrollable, maintainScrollVisibility } from '../../utils/scroll';

// @TODO: Add options
// @TODO: Add support for optgroups
// @TODO: Add support for no options

/* stimulusFetch: 'lazy' */
export default class AutocomplteController extends Controller {
  declare selectEl: HTMLSelectElement;
  declare comboEl: HTMLElement;
  declare textBoxEl: HTMLInputElement;
  declare listboxEl: HTMLUListElement;
  declare statusEl: HTMLElement;
  declare activeOptionId: string | null;

  declare showInitalResultsValue: boolean;
  declare minMatchCharLengthValue: number;
  static values = {
    showInitalResults: {
      type: Boolean,
      default: true,
    },
    minMatchCharLength: {
      type: Number,
      default: 2,
    },
  };

  baseId = '';
  open = false;

  connect(): void {
    this.selectEl = this.element.querySelector('select') as HTMLSelectElement;
    this.baseId = this.selectEl.id;

    // Check if the select ist already set up
    if (!this.selectEl.hasAttribute('aria-hidden')) {
      this.createTextBox();
      this.createMenu();
      anchorPositioning(this.textBoxEl, this.listboxEl);
      this.hideSelectBox();
      this.createStatusBox();
      this.setupMutationObserver();

      // Close menu if click is not in the controller
      document.addEventListener(
        'click',
        (event: MouseEvent) => {
          const target = event.target as HTMLElement;
          const parentAutocomplete = target?.closest('[data-controller*="input--autocomplete"]');
          if (this.open && (!parentAutocomplete || parentAutocomplete !== this.element)) {
            this.onElementLeave();
          }
        },
        false,
      );
    }
  }

  createTextBox(): void {
    this.comboEl = document.createElement('div');
    this.comboEl.classList.add('picklist__wrapper', 'dropdown-trigger', 'dropdown-trigger--click');

    this.textBoxEl = document.createElement('input');
    this.textBoxEl.setAttribute('type', 'text');
    this.textBoxEl.setAttribute('role', 'combobox');
    this.textBoxEl.setAttribute('aria-controls', `listbox-${this.baseId}`);
    this.textBoxEl.setAttribute('aria-expanded', 'false');
    this.textBoxEl.setAttribute('aria-haspopup', 'listbox');
    this.textBoxEl.setAttribute('aria-activedescendant', '');
    this.textBoxEl.setAttribute('aria-autocomplete', 'none');
    this.textBoxEl.setAttribute('autocomplete', 'off');
    this.textBoxEl.setAttribute('autocapitalize', 'none');
    this.textBoxEl.classList.add('input');
    // this.textBoxEl.id = this.baseId;

    if (this.selectEl.disabled) {
      this.textBoxEl.disabled = true;
    }

    // Add empty Option als placeholder text
    const placeholderOption = this.selectEl.querySelector('option[value=""]');
    if (placeholderOption && placeholderOption.textContent) {
      this.textBoxEl.setAttribute('placeholder', placeholderOption.textContent);
    }

    // Set inital select value
    const selectedOption: HTMLOptionElement | null = this.selectEl.querySelector('option[selected]');
    if (selectedOption) {
      const selectedVal = selectedOption.value;

      if (selectedVal.trim().length > 0) {
        this.textBoxEl.value = selectedOption.textContent ? selectedOption.textContent : '';
      }
    }

    this.comboEl.append(this.textBoxEl);
    const selectContainer = this.element.querySelector('.select__container');
    this.element.insertBefore(this.comboEl, selectContainer);

    // add event listeners
    this.textBoxEl.addEventListener('click', (event) => this.onTextBoxClick(event));
    this.textBoxEl.addEventListener('keydown', (event) => this.onTextBoxKeyDown(event));
    this.textBoxEl.addEventListener('keyup', (e) => this.onTextBoxKeyUp(e));
  }

  createMenu(): void {
    this.listboxEl = document.createElement('ul');
    this.listboxEl.id = `listbox--${this.baseId}`;
    this.listboxEl.setAttribute('role', 'listbox');
    this.listboxEl.classList.add('listbox', 'listbox--vertical', 'dropdown');
    this.listboxEl.dataset.size = 'fluid';
    this.listboxEl.dataset.length = '5';
    this.comboEl.append(this.listboxEl);
  }

  hideSelectBox(): void {
    // Move the focus if the select had focus
    if (document.activeElement === this.selectEl) {
      this.textBoxEl.focus();
    }
    this.selectEl.setAttribute('aria-hidden', 'true');
    this.selectEl.setAttribute('tabindex', '-1');
    this.selectEl.parentElement?.classList.add('assistive-text');
    // this.selectEl.removeAttribute('id');
  }

  createStatusBox(): void {
    this.statusEl = document.createElement('div');
    this.statusEl.setAttribute('aria-live', 'polite');
    this.statusEl.setAttribute('role', 'status');
    this.statusEl.classList.add('assistive-text');
    this.element.append(this.statusEl);
  }

  setupMutationObserver(): void {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          if (!this.selectEl.value) {
            this.textBoxEl.value = '';
          }
          this.buildMenuWithState();
        }
        if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
          this.textBoxEl.disabled = this.selectEl.disabled;
        }
      });
    });

    observer.observe(this.selectEl, { childList: true, attributes: true });
  }

  buildMenu(options: AutocompleteOption[] | FuseResult<AutocompleteOption>[]) {
    clearOptions(this.listboxEl);

    if (options.length) {
      options.forEach((option) => {
        const item = option.item ? option.item : option;
        const optionEl = document.createElement('li');
        const isCurrentOption = this.selectEl.value === item.value;
        optionEl.id = `${this.baseId}-option--${item.value}`;
        optionEl.setAttribute('role', 'option');
        optionEl.setAttribute('aria-selected', isCurrentOption.toString());
        optionEl.classList.add('listbox__option');
        if (item.disabled) {
          optionEl.setAttribute('aria-disabled', 'true');
        }
        optionEl.dataset.optionValue = item.value;
        if (option.matches) {
          optionEl.innerHTML = highlightResult(item.text, option.matches[0].indices, this.minMatchCharLengthValue);
        } else {
          optionEl.innerText = item.text;
        }
        this.listboxEl.append(optionEl);
      });
    } else {
      this.listboxEl.innerHTML = '<li class="listbox__option listbox__option--plain">Kein Ergebnis</li>';
    }

    const optionsElms = this.listboxEl.querySelectorAll('[role=option]');
    optionsElms.forEach((option) => {
      option.addEventListener('click', (event) => this.selectOption(event.currentTarget as HTMLElement));
    });
    this.listboxEl.scrollTop = 0;
  }

  updateStatus(optionsCount: number, disabledOptionsCount: number | null = null): void {
    if (optionsCount === 0) {
      this.statusEl.innerText = 'No results.';
    } else {
      this.statusEl.innerText = `
        ${optionsCount} option${optionsCount !== 1 ? 's' : ''} available${
          disabledOptionsCount
            ? `, ${disabledOptionsCount} disabled option${disabledOptionsCount !== 1 ? 's' : ''}`
            : ''
        }.`;
    }
  }

  onTextBoxClick(e: MouseEvent): void {
    clearOptions(this.listboxEl);
    this.buildMenuWithState();
    this.updateMenuState(this.showInitalResultsValue, false);
    const target = e.currentTarget as HTMLInputElement;
    if (target && typeof target.select === 'function') {
      target.select();
    }
  }

  onTextBoxKeyDown(event: KeyboardEvent): void {
    const first = getFirstSelectableOption(this.listboxEl);
    const last = getLastSelectableOption(this.listboxEl);

    switch (event.key) {
      // this ensures that when users tabs away
      // from textbox that the normal tab sequence
      // is adhered to. We hide the options, which
      // removes the ability to focus the options
      case 'Tab':
        this.onElementLeave();
        break;
      case 'ArrowDown':
        this.onTextBoxDownArrow();
        break;
      case 'ArrowUp':
        this.onTextBoxUpArrow();
        break;
      case 'Home':
        if (this.open && first) {
          event.preventDefault();
          this.highlightOption(first);
        }
        break;
      case 'End':
        if (this.open && last) {
          event.preventDefault();
          this.highlightOption(last);
        }
        break;
      case 'Enter':
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  onTextBoxKeyUp(event: KeyboardEvent): void {
    switch (event.key) {
      // ignore these keys otherwise
      // the menu will show briefly
      case 'ArrowLeft':
      case 'ArrowRight':
      case ' ':
      case 'Shift':
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      case 'Enter':
        this.onTextBoxEnter(event);
        break;
      case 'Escape':
        this.onTextBoxEscape(event);
        break;
      default:
        this.onTextBoxType();
        break;
    }
  }

  onTextBoxType() {
    const currentValueLength = this.textBoxEl.value.trim().length;

    if (currentValueLength >= this.minMatchCharLengthValue) {
      const options = this.getFilteredOptions(this.textBoxEl.value.trim());
      const disabledOptions = options.filter((option) => option.item.disabled);
      this.buildMenu(options);
      this.updateMenuState(true);
      this.updateStatus(options.length, disabledOptions.length);

      // Select first option
      const firstOption = getFirstSelectableOption(this.listboxEl);
      if (options.length > 0 && firstOption) {
        this.highlightOption(firstOption);
      }
    } else {
      // Reset Options if the input field is empty
      if (currentValueLength === 0) {
        this.setValue('');
      }

      // Close menu
      this.updateMenuState(false);
    }
  }

  selectOption(option: HTMLElement) {
    if (option.getAttribute('aria-disabled') !== 'true') {
      const value = option.dataset.optionValue;
      if (value) {
        this.setValue(value);
      }
      this.updateMenuState(false, true);
    }
  }

  buildMenuWithState(): void {
    const value = this.textBoxEl.value.trim();
    // Empty value or exactly matches an option
    // then show all the options
    if (value.length === 0 || getSelectOptionByText(this.selectEl, value)) {
      this.buildMenu(getSelectOptions(this.selectEl));
      this.updateMenuState(true);
    } else {
      const options = this.getFilteredOptions(value);
      if (options.length > 0) {
        this.buildMenu(options);
        this.updateMenuState(true);
      }
    }
  }

  onTextBoxDownArrow() {
    this.buildMenuWithState();

    const currentOption = document.getElementById(this.activeOptionId) as HTMLLIElement;
    const nextOption = this.activeOptionId
      ? getNextSelectableOption(currentOption)
      : getFirstSelectableOption(this.listboxEl);
    if (nextOption) {
      this.highlightOption(nextOption);
    }
  }

  onTextBoxUpArrow(): void {
    if (this.activeOptionId) {
      const currentOption = document.getElementById(this.activeOptionId) as HTMLLIElement;
      const previousOption = this.activeOptionId ? getPreviousSelectableOption(currentOption) : null;
      if (previousOption) {
        this.highlightOption(previousOption);
      } else {
        // Close menu
        this.updateMenuState(false, true);
      }
    }
  }

  onTextBoxEnter(event: KeyboardEvent): void {
    if (this.open) {
      event.preventDefault();
      const activeOption = this.activeOptionId ? (document.getElementById(this.activeOptionId) as HTMLLIElement) : null;
      if (activeOption) {
        this.selectOption(activeOption);
      }
    } else {
      this.buildMenuWithState();
      this.updateMenuState(true);
    }
  }

  onTextBoxEscape(event: KeyboardEvent): void {
    event.preventDefault();
    this.updateMenuState(false);
  }

  onElementLeave(): void {
    // Deselect current Option if text does not match
    if (getSelectOptionByValue(this.selectEl, this.selectEl.value)?.innerText !== this.textBoxEl.value) {
      this.setValue('');
    }
    this.updateMenuState(false, false);
  }

  highlightOption(optionEl: HTMLLIElement) {
    if (this.activeOptionId) {
      const activeOption = document.getElementById(this.activeOptionId);
      activeOption?.classList.remove('has-focus');
    }

    this.textBoxEl.setAttribute('aria-activedescendant', optionEl.id);

    optionEl.classList.add('has-focus');

    if (this.open && isScrollable(this.listboxEl)) {
      maintainScrollVisibility(optionEl, this.listboxEl);
    }
    this.activeOptionId = optionEl.id;
  }

  updateMenuState(open: boolean, callFocus = false) {
    this.open = open;
    this.textBoxEl?.setAttribute('aria-expanded', `${open}`);

    if (open) {
      this.comboEl.classList.add('is-open');
    } else {
      this.comboEl.classList.remove('is-open');
      this.activeOptionId = null;
      clearOptions(this.listboxEl);
    }

    if (callFocus) {
      this.textBoxEl.focus();
    }
  }

  getFilteredOptions(value: string) {
    return new Fuse(getSelectOptions(this.selectEl), {
      keys: ['text', 'value'],
      isCaseSensitive: false,
      ignoreLocation: true,
      threshold: 0.2,
      minMatchCharLength: this.minMatchCharLengthValue,
      includeMatches: true,
    }).search(value);
  }

  setValue(val: string): void {
    if (this.selectEl.value !== val) {
      // Remove old value
      const oldOption = getSelectOptionByValue(this.selectEl, this.selectEl.value);
      oldOption?.removeAttribute('selected');

      // Set new value
      this.selectEl.value = val;
      this.selectEl.dispatchEvent(new Event('change'));
      const newAction = getSelectOptionByValue(this.selectEl, val);
      newAction?.setAttribute('selected', 'selected');

      // Update Textbox
      const text = newAction?.textContent;
      if (text && val.trim().length > 0) {
        this.textBoxEl.value = text;
      } else {
        this.textBoxEl.value = '';
      }
    }
  }
}
