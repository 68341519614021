import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class SortableController extends Controller {
  sortable: Sortable;

  draggableValue: boolean;
  static values = {
    draggable: {
      type: Boolean,
      default: false,
    },
  };

  itemTargets: HTMLDivElement[];
  static targets = ['item'];

  connect(): void {
    this.setSortButtonStates();

    if (this.draggableValue) {
      this.sortable = new Sortable(this.element, {
        group: this.element.id,

        direction: 'vertical',

        handle: '.draggable-handle',
        ghostClass: 'draggable-ghost',
        chosenClass: 'draggable-chosen',
        dragClass: 'draggable-drag',

        forceFallback: 'true',
        onDragStart(e: DragEvent) {
          const target = e.target as HTMLElement | undefined;
          target?.classList.add('grabbing');
        },
        onDragEnd(e: DragEvent) {
          const target = e.target as HTMLElement | undefined;
          target?.classList.remove('grabbing');
        },
        onUpdate: () => this.onUpdate(),
      });
    }

    // Listen on Collection updates
    this.element.addEventListener('collection:limit', () => this.setSortButtonStates());
    this.element.addEventListener('collection:add', () => this.setSortButtonStates());
    this.element.addEventListener('collection:deleted', () => this.setSortButtonStates());
  }

  moveItemUp(event: PointerEvent) {
    const target = event.target as HTMLElement;
    const item = target.closest('[data-sortable-target="item"]');

    if (item && item.previousElementSibling) {
      const group = Array.from(this.element.children);

      // this.groupTarget?.insertBefore(item, item.previousElementSibling);
      this.swapItems(group.indexOf(item), group.indexOf(item.previousElementSibling));
    }
  }

  moveItemDown(event: PointerEvent) {
    const target = event.target as HTMLElement;
    const item = target.closest('[data-sortable-target="item"]');

    if (item && item.nextElementSibling) {
      const group = Array.from(this.element.children);

      this.swapItems(group.indexOf(item), group.indexOf(item.nextElementSibling));
    }
  }

  swapItems(oldIndex: number, newIndex: number) {
    if (newIndex > oldIndex) {
      this.element?.insertBefore(this.itemTargets[newIndex], this.itemTargets[oldIndex]);
    } else {
      this.element.insertBefore(this.itemTargets[oldIndex], this.itemTargets[newIndex]);
    }

    this.onUpdate();
  }

  onUpdate() {
    this.setSortButtonStates();

    this.element.dispatchEvent(new Event('sortable:update', { bubbles: true }));
  }

  setSortButtonStates() {
    this.itemTargets.forEach((item, index) => {
      const upBtn = item.querySelector('[data-action="sortable#moveItemUp"]');
      const downBtn = item.querySelector('[data-action="sortable#moveItemDown"]');
      if (index + 1 === 1) {
        upBtn?.setAttribute('disabled', 'disabled');
      } else {
        upBtn?.removeAttribute('disabled');
      }

      if (index + 1 === this.itemTargets.length) {
        downBtn?.setAttribute('disabled', 'disabled');
      } else {
        downBtn?.removeAttribute('disabled');
      }
    });
  }
}
