import { Controller } from '@hotwired/stimulus';

import { put } from '../utils/api';

/* stimulusFetch: 'lazy' */
export default class UserNotificationsController extends Controller {
  read(event: Event) {
    const frame = this.element.closest('turbo-frame#user_notifications_list[src]');
    put(event.params.url, {}, {}).then((response) => {
      if (response.type === 'success') {
        if (frame) {
          frame.reload();
        } else {
          location.reload();
        }
      }
    });
  }

  readAll(event: Event) {
    const frame = this.element.closest('turbo-frame#user_notifications_list[src]');
    put(event.params.url, {}, {}).then((response) => {
      if (response.type === 'success') {
        if (frame) {
          frame.reload();
        } else {
          location.reload();
        }
      }
    });
  }
}
