import { getJson, patchJson } from './api';

const ROUTE_OBJECT = {
  name: null,
  action: null,
  currentuser: null,
  park: null,
  objectType: null,
  objectId: null,
  settings: {},
};

/**
 * get the settings for all routes
 * @return {Object}       route settings
 */
// const getAllRouteSettings = function () {
//   return getJson('/user/settings/view_settings_per_route.json').then(
//     (response) => {
//       ROUTE_OBJECT.settings = response;
//     },
//   );
// };

/**
 * get the settings for a specific route
 * @param  {string} route specific route
 * @return {Object}       route settings
 */
// const getRouteSettings = function (route: string) {
//   return getJson(
//     `/user/settings/view_settings_per_route.json?path=[${route}]`,
//     {},
//   ).then((response) => {
//     ROUTE_OBJECT.settings[route] = response;
//   });
// };

/**
 * Update whole settings per route object
 */
// const setUserSettings = function (settings) {
//   return patchJson(
//     '/user/settings/view_settings_per_route.json',
//     null,
//     JSON.stringify(settings),
//   ).then((response) => {
//     ROUTE_OBJECT.settings = response;
//   });
// };

/**
 * Set route settings for one specific route
 */
const setRouteSetting = function (route: string, key: string, value: any) {
  // Set Settings local
  // if (ROUTE_OBJECT.settings[route][key]) {
  //   ROUTE_OBJECT.settings[route][key] = value;
  // } else {
  //   ROUTE_OBJECT.settings[route] = {};
  //   ROUTE_OBJECT.settings[route][key] = value;
  // }

  const BODY = {};
  BODY[route] = {};
  BODY[route][key] = value;

  // Persist Settings on the server
  return patchJson('/user/settings/view_settings_per_route.json', null, BODY).then((response) => {
    ROUTE_OBJECT.settings = response;
  });
};

/**
 * Get a specific setting of viewSettingsPerRoute
 */
// const getRouteSetting = function (p) {
//   return getJson(
//     `/user/settings/view_settings_per_route.json?path=[${p.route}][${p.setting}]`,
//   ).then((response) => {
//     setRouteSetting({
//       route: p.route,
//       key: p.setting,
//       value: response,
//     });
//   });
// };

// const setupRoute = function () {
//   if (window.ROUTE) {
//     ROUTE_OBJECT.name = window.ROUTE;
//     if (typeof ROUTE_OBJECT.name === 'string' && !ROUTE_OBJECT.name.startsWith('selfservice_')) {
//       getRouteSettings(ROUTE_OBJECT.name);
//     }
//   }
//   if (window.ACTION) {
//     ROUTE_OBJECT.action = window.ACTION;
//   }
//   if (window.USER) {
//     ROUTE_OBJECT.currentuser = window.USER;
//   }
//   if (window.PARK) {
//     ROUTE_OBJECT.park = window.PARK;
//   }
//   if (window.ENTITY_TYPE) {
//     ROUTE_OBJECT.objectType = window.ENTITY_TYPE;
//   }
//   if (window.ENTITY_ID) {
//     ROUTE_OBJECT.objectId = window.ENTITY_ID;
//   }
// };

export {
  // getAllRouteSettings,
  // getRouteSettings,
  // setUserSettings,
  // getRouteSetting,
  setRouteSetting,
  // setupRoute,
  ROUTE_OBJECT,
};
