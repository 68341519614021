export const setValid = (inputEl: HTMLElement): void => {
  inputEl.setAttribute('aria-invalid', 'false');
  inputEl.classList.remove('is-invalid');
};

export const setInvalid = (inputEl: HTMLElement): void => {
  inputEl.setAttribute('aria-invalid', 'true');
  inputEl.classList.add('is-invalid');
};

export const setHelpText = (input: HTMLElement, text: string): void => {
  const formEl = input.closest('.form-element') as HTMLDivElement;
  const helpEl = formEl?.querySelector('.form-element__help') as HTMLDivElement;
  if (helpEl) {
    helpEl.innerText = text;
  } else {
    const newHelpEl = document.createElement('div');
    newHelpEl.innerText = text;
    formEl.append(newHelpEl);
  }
};

export const setErrorMessage = (inputEl: HTMLElement, errorMessage: string): void => {
  // Get the error message element for the current input element.
  const formEl = inputEl.closest('.form-element') as HTMLDivElement;
  let errorEl = formEl?.querySelector('.form-element__error') as HTMLDivElement;
  if (errorEl) {
    errorEl.textContent = errorMessage;
  } else {
    errorEl = document.createElement('div');
    errorEl.classList.add('form-element__error');
    errorEl.textContent = errorMessage;
    formEl.append(errorEl);
  }
};

// The legend error element is used to provide an inclusive experience for
// users who use assistive technology. When a checkbox is in focus, the error
// message is read out loud since the error message is in the legend.
// The legend error element is hidden from sighted users.
// https://blog.tenon.io/accessible-validation-of-checkbox-and-radiobutton-groups/#dynamicallyinjectingtheerrortextintothelegendoursolution
export const setLegendErrorMessage = (fieldsetEl: HTMLFieldSetElement, errorMessage: string): void => {
  const legendEl = fieldsetEl.querySelector('legend.form-element__label');
  let legendErrorEl = legendEl?.querySelector('.assistive-text');
  if (legendErrorEl) {
    legendErrorEl.textContent = errorMessage;
  } else {
    legendErrorEl = document.createElement('span');
    legendErrorEl.classList.add('assistive-text');
    legendErrorEl.textContent = errorMessage;
    legendEl?.append(legendErrorEl);
  }
};

export const addErrorMessage = (input: HTMLElement, text: string): void => {
  const formEl = input.closest('.form-element') as HTMLDivElement;
  const newErrorEl = document.createElement('div');
  newErrorEl.classList.add('form-element__error');
  newErrorEl.innerText = text;
  formEl.append(newErrorEl);

  setInvalid(input);
};

export const removeAllErrorMessages = (input: HTMLElement): void => {
  const formEl = input.closest('.form-element') as HTMLDivElement;
  const errorEls = formEl?.querySelectorAll('.form-element__error') as NodeListOf<HTMLDivElement>;
  for (let index = errorEls.length - 1; index >= 0; index--) {
    formEl.removeChild(errorEls[index]);
  }
  setValid(input);
};
