import { Controller } from '@hotwired/stimulus';

import { numberFormater } from '../../utils/number';

/* stimulusFetch: 'lazy' */
export default class DataEntryController extends Controller {
  declare readonly deliveryPointTargets: HTMLDivElement[];
  static targets = ['deliveryPoint'];

  connect() {
    this.setYield();
    this.setYieldOnNegativePrices();
  }

  setYield(): void {
    this.deliveryPointTargets.forEach((deliveryPoint) => {
      const inputs = deliveryPoint.querySelectorAll('[data-yield]') as NodeListOf<HTMLInputElement>;
      const yieldSumEl = deliveryPoint.querySelector('[data-yieldSum]');
      let yieldSum = 0;

      inputs.forEach((input) => {
        yieldSum += parseFloat(input.value.replaceAll('.', '').replaceAll(',', '.'));
      });
      if (yieldSumEl) {
        yieldSumEl.innerHTML = numberFormater(yieldSum.toString().replace('.', ','));
      }
    });
  }

  setYieldOnNegativePrices(): void {
    this.deliveryPointTargets.forEach((deliveryPoint) => {
      const inputs = deliveryPoint.querySelectorAll('[data-yieldOnNegativePrices]') as NodeListOf<HTMLInputElement>;
      const yieldSumEl = deliveryPoint.querySelector('[data-yieldOnNegativePricesSum]');
      let yieldSum = 0;

      inputs.forEach((input) => {
        yieldSum += parseFloat(input.value.replaceAll('.', '').replaceAll(',', '.'));
      });
      if (yieldSumEl) {
        yieldSumEl.innerHTML = numberFormater(yieldSum.toString().replace('.', ','));
      }
    });
  }

  setSubmitLabel(event: Event): void {
    const submitEl = document.getElementById('monthly_report_data_entry_dataEntrySubmit');
    const toggle = event.target as HTMLInputElement;

    if (submitEl) {
      if (toggle.checked) {
        submitEl.dataset.label = submitEl?.innerText;
        if (submitEl.dataset.labelfinalize) {
          submitEl.innerText = submitEl.dataset.labelfinalize;
        }
      } else if (submitEl.dataset.label) {
        submitEl.innerText = submitEl.dataset.label;
      }
    }
  }
}
