import { Node, mergeAttributes } from '@tiptap/core';

export type PlacholderBlockAtts = {
  id: string;
  label: string;
  content: string;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    blockPlaceholder: {
      /**
       * Insert an inline placeholder
       */
      insertBlockPlaceholder: (attrs: PlacholderBlockAtts) => ReturnType;
    };
  }
}

const PlaceholderBlock = Node.create({
  name: 'placeholderblock',
  group: 'block',

  atom: true,
  selectable: true,
  draggable: true,
  code: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-placeholder-id'),
        renderHTML: (attributes) => ({
          'data-placeholder-id': attributes.id,
        }),
      },
      content: {
        default: null,
        parseHTML: (element) => element.innerHTML,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-placeholder-id]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const template = document.createElement('template');
    template.innerHTML = HTMLAttributes.content;
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, {
        'data-placeholder-id': HTMLAttributes['data-placeholder-id'],
      }),
      template.content.firstElementChild,
    ];
  },

  addCommands() {
    return {
      insertBlockPlaceholder:
        (attrs: PlacholderBlockAtts) => ({ state, dispatch }) => {
          const { selection } = state;
          const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
          const node = this.type.create(attrs);
          const transaction = state.tr.insert(position, node);
          dispatch(transaction);
        },
    };
  },
});

export default PlaceholderBlock;
