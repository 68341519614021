import Url from 'domurl';

const deleteQuery = function (input: string, parameter) {
  const url = new Url(input);

  delete url.query[parameter];

  return url.toString();
};

const setQuery = function (input: string, parameter) {
  const url = new Url(input);

  url.query[parameter.key] = parameter.value;

  return url.toString();
};

const mergeQuery = function (input: string, parameters) {
  const url = new Url(input);

  if (parameters instanceof Object) {
    for (const paramenter in parameters) {
      if (Object.prototype.hasOwnProperty.call(parameters, paramenter)) {
        url.query[paramenter] = parameters[paramenter];
      }
    }
  }

  if (parameters instanceof Array) {
    throw new Error('parameters vom Typ Array sind nicht implementiert.');
  }

  return url.toString();
};

const makeJsonUrl = function (input: string) {
  const url = new Url(input);

  if (url.path.includes('.json')) {
    return input;
  }

  url.path = `${url.path}.json`;

  return url.toString();
};

export {
  deleteQuery, makeJsonUrl, mergeQuery, setQuery,
};
