import { Controller } from '@hotwired/stimulus';
import { updateValidationStateForInput, validateInputGroup } from '../utils/validation';

/* stimulusFetch: 'lazy' */
export default class FormController extends Controller<HTMLFormElement> {
  inputs: HTMLInputElement[] | null;
  inputGroupInputs: HTMLInputElement[] | null;

  connect() {
    // Disable native Form validation
    this.element.setAttribute('novalidate', '');

    this.element.addEventListener('submit', (event) => this.onSubmit(event));
  }


  isFormValid() {
    // The isFormValid boolean respresents all inputs that can
    // be validated with the Constraint Validation API.
    return this.element.checkValidity();
  }

  onSubmit(event: SubmitEvent) {
    const inputGroupInputs = Array.from(
      this.element.querySelectorAll(
        'fieldset[data-required] input[type="checkbox"], fieldset[aria-required] input[type="radio"]',
      ),
    ) as HTMLInputElement[];
    const inputs = Array.from(
      this.element.querySelectorAll(
        '[data-controller="validate-form-element"] input:not([type="checkbox"], [type="radio"]), [data-controller="validate-form-element"] select, [data-controller="validate-form-element"] textarea',
      ),
    ) as HTMLInputElement[];

    // Update the validation UI state for all inputs.
    inputs?.forEach((inputEl) => updateValidationStateForInput(inputEl));

    // Fields that cannot be validated with the Constraint Validation API need
    // to be validated manually.
    const areRadiosAndCheckboxesValid = inputGroupInputs?.every((checkbox) => validateInputGroup(checkbox));

    // If any of the validation checks fail, prevent the form from submitting.
    if (!this.isFormValid() || !areRadiosAndCheckboxesValid) {
      event.preventDefault();

      const invalidFields = this.element.querySelectorAll(
        ':invalid, fieldset.is-invalid input',
      ) as NodeListOf<HTMLElement>;

      console.info(`FormValidation: Form is ${this.isFormValid() ? 'valid' : 'invalid'})`);
      console.info(`FormValidation: Radios/Checkboxes are ${areRadiosAndCheckboxesValid ? 'valid' : 'invalid'})`);
      console.info(invalidFields);

      // Set the focus to the first invalid input or scroll the fieldset into view
      if (invalidFields.length > 0) {
        const firstInvalidInputEl = invalidFields[0];
        if (firstInvalidInputEl.nodeName === 'FIELDSET') {
          firstInvalidInputEl.scrollIntoView();
        } else {
          firstInvalidInputEl.focus();
        }
      }
    }
  }
}
