import { Controller } from '@hotwired/stimulus';
import { patch } from '../utils/api';

/* stimulusFetch: 'lazy' */
export default class AttachmentStatusController extends Controller {
  declare urlValue: string;
  static values = {
    url: String,
  };

  inProgress = false;

  toggleState(event: Event) {
    event.preventDefault();
    if (!this.inProgress) {
      const checkbox = event.target as HTMLInputElement;
      const initalState = !checkbox.checked;

      this.inProgress = true;
      checkbox.disabled = true;

      patch(this.urlValue, {}, { todoState: checkbox.checked ? 'done' : 'open' }).then((response) => {
        if (response.todoState) {
          checkbox.checked = response.todoState === 'done';
        } else {
          checkbox.checked = initalState;
        }

        checkbox.disabled = false;
        this.inProgress = false;
      });
    }
  }
}
