import {
  findChildrenInRange, Node, nodeInputRule, Tracker,
} from '@tiptap/core';

export interface FigureOptions {
  HTMLAttributes: Record<string, any>;
  // size: string[],
  // alignment: string[],
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    figure: {
      /**
       * Add a figure element
       */
      setFigure: (options: { src: string; alt?: string; title?: string; caption?: string }) => ReturnType;

      /**
       * Converts an image to a figure
       */
      imageToFigure: () => ReturnType;

      /**
       * Converts a figure to an image
       */
      figureToImage: () => ReturnType;
    };
  }
}

export const inputRegex = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export const FigureNode = Node.create<FigureOptions>({
  name: 'figure',

  addOptions() {
    return {
      HTMLAttributes: {},
      // size: ['full', 'half', 'quarter'],
      // alignment: ['inline', 'float-left', 'float-right'],
    };
  },

  group: 'block',

  content: 'inline*',

  draggable: true,
  selectable: true,

  isolating: true,

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (element) => element.querySelector('img')?.getAttribute('src'),
      },
      alt: {
        default: null,
        parseHTML: (element) => element.querySelector('img')?.getAttribute('alt'),
      },
      title: {
        default: null,
        parseHTML: (element) => element.querySelector('img')?.getAttribute('title'),
      },
      size: {
        default: 'intrinsic',
        parseHTML: (element) => element.dataset.size,
        renderHTML: (attributes) => ({
          'data-size': attributes.size,
        }),
      },
      alignment: {
        default: 'inline',
        parseHTML: (element) => element.dataset.alignment,
        renderHTML: (attributes) => ({
          'data-alignment': attributes.alignment,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'figure',
        contentElement: 'figcaption',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'figure',
      {
        'data-alignment': HTMLAttributes['data-alignment'],
        'data-size': HTMLAttributes['data-size'],
      },
      [
        'img',
        {
          src: HTMLAttributes.src,
          alt: HTMLAttributes.alt,
          title: HTMLAttributes.title,
          draggable: false,
          contenteditable: false,
        },
      ],
      ['figcaption', 0],
    ];
  },

  addCommands() {
    return {
      setFigure:
        ({ caption, ...attrs }) => ({ chain }) => chain()
          .insertContent({
            type: this.name,
            attrs,
            content: caption ? [{ type: 'text', text: caption }] : [],
          })
        // set cursor at end of caption field
          .command(({ tr, commands }) => {
            const { doc, selection } = tr;
            const position = doc.resolve(selection.to - 2).end();

            return commands.setTextSelection(position);
          })
          .run(),

      imageToFigure:
        () => ({ tr, commands }) => {
          const { doc, selection } = tr;
          const { from, to } = selection;
          const images = findChildrenInRange(doc, { from, to }, (node) => node.type.name === 'image');

          if (!images.length) {
            return false;
          }

          const tracker = new Tracker(tr);

          return commands.forEach(images, ({ node, pos }) => {
            const mapResult = tracker.map(pos);

            if (mapResult.deleted) {
              return false;
            }

            const range = {
              from: mapResult.position,
              to: mapResult.position + node.nodeSize,
            };

            return commands.insertContentAt(range, {
              type: this.name,
              attrs: {
                src: node.attrs.src,
                alt: node.attrs.alt,
                title: node.attrs.title,
                size: node.attrs.size,
                alignment: node.attrs.alignment,
              },
            });
          });
        },

      figureToImage:
        () => ({ tr, commands }) => {
          const { doc, selection } = tr;
          const { from, to } = selection;
          const figures = findChildrenInRange(doc, { from, to }, (node) => node.type.name === this.name);

          if (!figures.length) {
            return false;
          }

          const tracker = new Tracker(tr);

          return commands.forEach(figures, ({ node, pos }) => {
            const mapResult = tracker.map(pos);

            if (mapResult.deleted) {
              return false;
            }

            const range = {
              from: mapResult.position,
              to: mapResult.position + node.nodeSize,
            };

            return commands.insertContentAt(range, {
              type: 'image',
              attrs: {
                src: node.attrs.src,
                alt: node.attrs.alt,
                title: node.attrs.title,
                size: node.attrs.size,
                alignment: node.attrs.alignment,
              },
            });
          });
        },
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        // getAttributes: (match) => {
        //   const [, src, alt, title, size, alignment] = match;

        //   return {
        //     src, alt, title, size, alignment,
        //   };
        // },
      }),
    ];
  },
});

export default FigureNode;
