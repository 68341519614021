import { Controller } from '@hotwired/stimulus';
import { restDelete } from '../utils/api';
import { createFlashMessage } from '../utils/flash-message';

/* stimulusFetch: 'lazy' */
export default class SafeDeleteController extends Controller {
  declare deleteUrlValue: string;
  declare reloadFrameValue: boolean;
  static values = {
    deleteUrl: String,
    reloadFrame: {
      type: Boolean,
      default: false,
    },
  };

  declare toggleButtonTarget: HTMLButtonElement;
  static targets = ['toggleButton'];

  toggleDelete() {
    this.toggleButtonTarget.setAttribute(
      'aria-pressed',
      this.toggleButtonTarget.getAttribute('aria-pressed') === 'true' ? 'false' : 'true',
    );
  }

  delete(event: Event) {
    event.preventDefault();
    const itemTarget = this.element.closest('[data-safe-delete-target="item"]');
    const parentFrame = this.element.closest('turbo-frame[src]');

    restDelete(this.deleteUrlValue).then((response) => {
      if (response.type === 'success') {
        createFlashMessage(response.type, response.message);
        itemTarget?.remove();
        if (this.reloadFrameValue) {
          parentFrame?.reload();
        }
      } else {
        createFlashMessage('error', 'Das Objekt konnte nicht gelöscht werden');
      }
    });
  }
}
