import { Editor } from '@tiptap/core';
import { Controller } from '@hotwired/stimulus';
import BubbleMenu from '@tiptap/extension-bubble-menu';

// Inline
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Focus from '@tiptap/extension-focus';
import Gapcursor from '@tiptap/extension-gapcursor';
import History from '@tiptap/extension-history';
// import Placeholder from '@tiptap/extension-placeholder';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';

// Inline
import Bold from '@tiptap/extension-bold';
import Code from '@tiptap/extension-code';
import Link from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight';
import Italic from '@tiptap/extension-italic';
import Text from '@tiptap/extension-text';
import Strike from '@tiptap/extension-strike';

import Blockquote from '@tiptap/extension-blockquote';
import BulletList from '@tiptap/extension-bullet-list';
import CodeBlock from '@tiptap/extension-code-block';
import Heading, { Level } from '@tiptap/extension-heading';
import HardBreak from '@tiptap/extension-hard-break';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import ListItem from '@tiptap/extension-list-item';
import Paragraph from '@tiptap/extension-paragraph';
import OrderedList from '@tiptap/extension-ordered-list';

import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { Typography } from './tiptap/typography';

import ContentPlacebolderInline from './tiptap/marks/content-placeholder-inline';
import ContentPlacebolderBlock from './tiptap/nodes/content-placeholder-block';
import Title from './tiptap/nodes/title';
import Image from './tiptap/nodes/image';
import { FigureNode } from './tiptap/nodes/figure';

const CustomDocument = Document.extend({
  content: 'title block*',
});

async function uploadImage(
  file: File,
  imageUploadUrlValue: string,
  filenameReplacePatternValue: string,
  maxFilenameLengthValue: number,
) {
  const fileName = file.name.replaceAll(' ', '_').replaceAll(new RegExp(filenameReplacePatternValue, 'g'), '-');

  const fileExtension = fileName.includes('.') ? fileName.substring(fileName.lastIndexOf('.') + 1) : false;
  let truncatedFileName = fileName
    .substring(0, fileName.includes('.') ? fileName.lastIndexOf('.') : undefined)
    .substring(0, maxFilenameLengthValue - (fileExtension ? fileExtension.length + 1 : 0));
  truncatedFileName = fileExtension ? `${truncatedFileName}.${fileExtension}` : truncatedFileName;

  const url = imageUploadUrlValue.replace('--fileName--', truncatedFileName);

  await fetch(url, {
    method: 'PUT',
    body: file,
  });

  return url;
}

/* stimulusFetch: 'lazy' */
export default class EditorController extends Controller {
  declare contentTarget: HTMLDivElement;
  static targets = ['content'];

  declare readonly titleValue: boolean;
  // declare readonly placeholdersValue: boolean;
  declare readonly minimalValue: boolean;
  declare readonly filenameReplacePatternValue: string;
  declare readonly maxFilenameLengthValue: number;
  declare readonly hasImageUploadUrlValue: boolean;
  declare readonly imageUploadUrlValue: string;
  static values = {
    title: {
      type: Boolean,
      default: false,
    },
    placeholders: {
      type: Boolean,
      default: false,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    filenameReplacePattern: {
      type: String,
      default: '',
    },
    maxFilenameLength: Number,
    imageUploadUrl: {
      type: String,
      default: '',
    },
  };

  declare editor: Editor;

  connect(): void {
    if (!this.minimalValue) {
      this.element.classList.add('editor--md');
    }
    if (navigator.userAgent.includes('Win')) {
      this.element.classList.add('editor--windows');
    }
    if (navigator.userAgent.includes('Mac')) {
      this.element.classList.add('editor--mac');
    }
    this.editorSetup();
  }

  editorSetup() {
    const inputEl = this.element.querySelector('textarea');
    if (this.contentTarget.childNodes.length !== 0) {
      while (this.contentTarget.firstChild) {
        this.contentTarget.removeChild(this.contentTarget.firstChild);
      }
    }

    if (inputEl) {
      const extensions = this.extensions();
      this.editor = new Editor({
        element: this.contentTarget,
        extensions,
        content: inputEl.value,
        editorProps: {
          attributes: {
            class: 'prose',
          },
        },

        onUpdate({ editor }) {
          inputEl.value = editor.getHTML();
        },
        onSelectionUpdate: () => this.setMenuState(),
      });
    }
  }

  extensions() {
    const extensions = [];

    if (this.titleValue) {
      extensions.push(CustomDocument, Title);
    } else {
      extensions.push(Document);
    }

    // Basic setup
    extensions.push(
      History,
      Dropcursor,
      Gapcursor,
      Focus.configure({
        className: 'has-focus',
      }),
      Paragraph,
      Subscript,
      Superscript,
      Typography,
    );

    // Marks
    extensions.push(
      Bold,
      Code,
      Highlight,
      Italic,
      Link.configure({
        protocols: ['tel', 'mailto'],
        autolink: false,
        openOnClick: false,
      }),
      BubbleMenu.configure({
        pluginKey: 'linkMenu',
        element: this.element.querySelector('[data-link-overlay]'),
        shouldShow: ({ editor }) => editor.isActive('link'),
      }),
      Strike,
      Text,
    );

    // extensions.push(Placeholder.configure({
    //   emptyNodeClass: 'is-empty',
    //   placeholder: (node) => {
    //     if (node.node.type.name === 'title') {
    //       return 'Dokumententitel…';
    //     }
    //     return 'Schreiben Sie etwas…';
    //   },
    //   showOnlyWhenEditable: true,
    // }));

    if (!this.minimalValue) {
      extensions.push(
        Blockquote,
        BulletList,
        CodeBlock,
        this.titleValue
          ? Heading.configure({
            levels: [2, 3, 4, 5, 6],
          })
          : Heading,
        HardBreak,
        HorizontalRule,
        ListItem,
        OrderedList,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
      );

      extensions.push(ContentPlacebolderInline, ContentPlacebolderBlock);

      const menububble = this.element.querySelector('.editor__menububble');
      if (menububble) {
        extensions.push(
          BubbleMenu.configure({
            pluginKey: 'tableMenu',
            element: menububble as HTMLElement,
            shouldShow: ({ editor }) => editor.isActive('table'),
          }),
        );
      }
    }

    if (this.hasImageUploadUrlValue) {
      extensions.push(
        Image(uploadImage, this.imageUploadUrlValue, this.filenameReplacePatternValue, this.maxFilenameLengthValue),
        FigureNode,
      );

      const imageMenuBubble = this.element.querySelector('[data-image-overlay]');
      if (imageMenuBubble) {
        extensions.push(
          BubbleMenu.configure({
            pluginKey: 'iamgeMenu',
            element: imageMenuBubble as HTMLElement,
            shouldShow: ({ editor }) => editor.isActive('figure') || editor.isActive('image'),
          }),
        );
      }
    }

    return extensions;
  }

  setMenuState() {
    const actions = this.element.querySelectorAll('[data-node]');
    actions.forEach((action) => {
      if (action.dataset.node === 'heading') {
        if (
          this.editor.isActive('heading', {
            level: parseInt(action.dataset.level),
          })
        ) {
          action.parentNode.classList.add('is-active');
        } else {
          action.parentNode.classList.remove('is-active');
        }
      } else if (this.editor.isActive(action.dataset.node)) {
        if (action.hasAttribute('aria-pressed')) {
          action.setAttribute('aria-pressed', 'true');
        } else {
          action.classList.add('is-active');
        }
      } else if (action.hasAttribute('aria-pressed')) {
        action.setAttribute('aria-pressed', 'false');
      } else {
        action.classList.remove('is-active');
      }
    });
  }

  // Base Functions
  undo() {
    this.editor.commands.undo();
  }

  redo() {
    this.editor.commands.redo();
  }

  clearFormating() {
    this.editor.chain().focus().clearNodes().unsetAllMarks()
      .run();
  }

  //
  // Inline
  //

  toggleBold() {
    this.editor.chain().focus().toggleBold().run();
    this.setMenuState();
  }

  toggleItalic() {
    this.editor.chain().focus().toggleItalic().run();
    this.setMenuState();
  }

  toggleStrike() {
    this.editor.chain().focus().toggleStrike().run();
    this.setMenuState();
  }

  toggleHighlight() {
    this.editor.chain().focus().toggleHighlight().run();
    this.setMenuState();
  }

  toggleSuperscript() {
    this.editor.chain().focus().toggleSuperscript().run();
    this.setMenuState();
  }

  toggleSubscript() {
    this.editor.chain().focus().toggleSubscript().run();
    this.setMenuState();
  }

  insertSpecialCharacter(event: PointerEvent) {
    const targetEl = event.target as HTMLButtonElement;
    this.editor.chain().focus().insertContent(targetEl.dataset.character).run();
  }

  setLink() {
    const previousUrl = this.editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      this.editor.chain().focus().extendMarkRange('link').unsetLink()
        .run();

      return;
    }

    // update link
    this.editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' })
      .run();
  }

  unsetLink() {
    this.editor.chain().focus().unsetLink().run();
  }

  //
  // Block
  //

  toggleBulletList() {
    this.editor.chain().focus().toggleBulletList().run();
    this.setMenuState();
  }

  toggleOrderedList() {
    this.editor.chain().focus().toggleOrderedList().run();
  }

  toggleBlockquote() {
    this.editor.chain().focus().toggleBlockquote().run();
  }

  setParagraph() {
    this.editor.chain().focus().setParagraph().run();
    this.setMenuState();
  }

  setHeading(event: PointerEvent) {
    const headingLevel = parseInt(event.target.dataset.level, 10);
    this.editor
      .chain()
      .focus()
      .setHeading({ level: headingLevel as Level })
      .run();
    this.setMenuState();
  }

  insertTable() {
    this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run();
  }

  addColumnBefore() {
    this.editor.commands.addColumnBefore();
  }

  addColumnAfter() {
    this.editor.commands.addColumnAfter();
  }

  deleteColumn() {
    this.editor.commands.deleteColumn();
  }

  addRowBefore() {
    this.editor.commands.addRowBefore();
  }

  addRowAfter() {
    this.editor.commands.addRowAfter();
  }

  toggleHeaderRow() {
    this.editor.commands.toggleHeaderRow();
  }

  toggleHeaderColumn() {
    this.editor.commands.toggleHeaderColumn();
  }

  toggleHeaderCell() {
    this.editor.commands.toggleHeaderCell();
  }

  deleteRow() {
    this.editor.commands.deleteRow();
  }

  mergeOrSplit() {
    this.editor.commands.mergeOrSplit();
  }

  deleteTable() {
    this.editor.commands.deleteTable();
  }

  insertImage() {
    const fileInputEl = this.element.querySelector('input[type="file"].assistive-text') as HTMLInputElement;
    if (fileInputEl) {
      fileInputEl.addEventListener('change', () => {
        if (fileInputEl.files) {
          [...fileInputEl.files].forEach((file) => {
            uploadImage(
              file,
              this.imageUploadUrlValue,
              this.filenameReplacePatternValue,
              this.maxFilenameLengthValue,
            ).then((url) => {
              const node = this.editor.schema.nodes.image.create({
                src: url,
              });
              const transaction = this.editor.view.state.tr.replaceSelectionWith(node);
              this.editor.view.dispatch(transaction);
            });
          });
          fileInputEl.value = '';
        }
      });
      fileInputEl.click();
    }
  }

  deleteImage() {
    if (this.editor.isActive('figure')) {
      this.editor.commands.deleteNode('figure');
    }
    if (this.editor.isActive('image')) {
      this.editor.commands.deleteSelection();
    }
  }

  setImageAlt() {
    const alt = window.prompt(
      'Image alt',
      this.editor.getAttributes(this.editor.isActive('figure') ? 'figure' : 'image').alt,
    );

    // cancelled
    if (alt === null) {
      return;
    }

    // update alt
    this.editor
      .chain()
      .focus()
      .updateAttributes(this.editor.isActive('figure') ? 'figure' : 'image', {
        alt,
      })
      .run();
  }

  setImageAlignment(event: any) {
    if (event.params.align) {
      this.editor
        .chain()
        .focus()
        .updateAttributes(this.editor.isActive('figure') ? 'figure' : 'image', {
          alignment: event.params.align,
        })
        .run();
    }
  }

  setImageSize(event: any) {
    if (event.params.size) {
      this.editor
        .chain()
        .focus()
        .updateAttributes(this.editor.isActive('figure') ? 'figure' : 'image', {
          size: event.params.size,
        })
        .run();
    }
  }

  toggleImageCaption() {
    if (this.editor.can().imageToFigure()) {
      this.editor.chain().focus().imageToFigure().run();
    } else {
      this.editor.chain().focus().figureToImage().run();
    }
  }

  // Placeholders
  insertPlaceholder(event: PointerEvent) {
    const targetEl = event.target as HTMLButtonElement;
    if (event.params.type === 'mark') {
      this.editor.commands.insertInlinePlaceholder({
        id: event.params.id,
        label: event.params.label,
        value: event.params.value,
      });
    }

    if (event.params.type === 'node') {
      this.editor.commands.insertBlockPlaceholder({
        id: event.params.id,
        label: event.params.label,
        content: event.params.value,
      });
    }
  }
}
