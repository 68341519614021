import { setErrorMessage, setLegendErrorMessage } from './input';

/**
 * Return custom validity messages referencing a given input's ValidityState object.
 * @param {HTMLInputElement} inputEl The input element
 * @returns {string} A custom validity message for the given input element
 */
// eslint-disable-next-line complexity
export const getValidationMessageForInput = (inputEl: HTMLInputElement | HTMLSelectElement) => {
  // If the input is valid, return an empty string.
  if (inputEl.validity.valid) return '';

  /**
   * Validation constraints:
   * - required
   * - type=email
   */
  if (inputEl.type === 'email') {
    if (inputEl.validity.valueMissing) {
      return 'Bitte geben Sie eine E-Mailadresse an. (Pflichtfeld)';
    }
    if (inputEl.validity.typeMismatch) {
      return 'Bitte geben Sie eine gültige E-Mailadresse an.';
    }
  }

  /**
   * Validation constraints:
   * - required
   * - type=date
   */
  if (inputEl.type === 'date') {
    if (inputEl.validity.valueMissing) {
      return 'Bitte geben Sie ein Datum ein. (Pflichtfeld)';
    }
    if (inputEl.validity.typeMismatch) {
      return 'Bitte geben Sie ein gültiges Datum ein.';
    }
  }

  /**
   * Validation constraints:
   * - required
   * - type=datetime-local
   */
  if (inputEl.type === 'datetime-local') {
    if (inputEl.validity.valueMissing) {
      return 'Bitte geben Sie ein Datum samt Uhrzeit ein. (Pflichtfeld)';
    }
    if (inputEl.validity.typeMismatch) {
      return 'Bitte geben Sie ein gültiges Datum samt Uhrzeit ein.';
    }
  }

  /**
   * Validation constraints:
   * - required
   * - type=url
   */
  if (inputEl.type === 'url') {
    if (inputEl.validity.valueMissing) {
      return 'Bitte geben Sie eine URL an. (Pflichtfeld)';
    }
    if (inputEl.validity.typeMismatch) {
      return 'Bitte geben Sie eine gültige URL an.';
    }
  }

  // If all else fails, return the default built-in message.
  return inputEl.validationMessage;
};

export const updateValidationStateForInput = (inputEl: HTMLInputElement | HTMLSelectElement) => {
  // Check if the input is valid using the Constraint Validation API.
  // Yes, one line of code handles validation.
  // The Constraint Validation API is cool!
  const isInputValid = inputEl.checkValidity();

  // Handle optional fields that are empty
  if (!inputEl.required && inputEl.value === '' && isInputValid) {
    // Clear validation states.
    inputEl.classList.remove('is-valid', 'is-invalid');
  } else {
    // Toggle valid/invalid state class hooks.
    inputEl.classList.toggle('is-valid', isInputValid);
    inputEl.classList.toggle('is-invalid', !isInputValid);
  }

  if (isInputValid) {
    const parentFormElement = inputEl.closest('.form-element');
    if (parentFormElement) {
      parentFormElement.classList.remove('is-invalid');
    }
  }

  // Update the `aria-invalid` state when validation occurs
  // https://www.w3.org/WAI/WCAG21/Techniques/aria/ARIA21
  inputEl.setAttribute('aria-invalid', String(!isInputValid));

  // For demo purposes, show the custom validation messages for Part 4
  // IF PART 4:
  // - Use custom validity messages.
  // ELSE:
  // - Use the browser built-in localized validation message. Will be
  //   an empty string if input constraints are satisfied.
  //   https://developer.mozilla.org/en-US/docs/Web/API/HTMLObjectElement/validationMessage
  const validationMessage = getValidationMessageForInput(inputEl);

  // Use the browser's built-in localized validation message.
  // The validation message returns an empty string if the
  // validation constraints are satisfied.
  setErrorMessage(inputEl, validationMessage);
};

export const validateInputGroup = (inputEl: HTMLInputElement) => {
  // Get the fieldset element for the checkbox group.
  const checkboxFieldsetEl = inputEl.closest('fieldset');

  // Bail if no checkbox fieldset element is found.
  // Return true so this doesn't block form submission.
  if (!checkboxFieldsetEl) return true;

  // Get all the related checkboxes.
  const relatedCheckboxInputEls = document.querySelectorAll(`input[name="${inputEl.name}"]`);

  // Are any of the "interests" checkboxes checked? At least one is required.
  const formData = new FormData(inputEl.form as HTMLFormElement);
  const isValid = formData.getAll(inputEl.name).length > 0;

  // Need to place the `is-valid` class higher up in the DOM tree to
  // show a validation state icon (one icon for the group of checkboxes).
  checkboxFieldsetEl.classList.toggle('is-valid', isValid);
  checkboxFieldsetEl.classList.toggle('is-invalid', !isValid);
  // Also update aria-invalid on the fieldset.
  checkboxFieldsetEl.setAttribute('aria-invalid', String(!isValid));
  // Update the validation UI state for each checkbox.
  relatedCheckboxInputEls.forEach((checkboxInputEl) => {
    checkboxInputEl.classList.toggle('is-valid', isValid);
    checkboxInputEl.classList.toggle('is-invalid', !isValid);
  });

  // Update the validation error message.
  const errorMsg = isValid ? '' : 'Bitte mindestens eine Option auswählen.';

  // The error message is the same for both the legend and the visual error.
  setLegendErrorMessage(checkboxFieldsetEl, errorMsg);
  // The visual error message is for sighted users.
  setErrorMessage(inputEl, errorMsg);

  // Return the validation state.
  return isValid;
};
