import { Controller } from '@hotwired/stimulus';

import { getElementFromSelector } from '../utils/target-api';

/* stimulusFetch: 'lazy' */
export default class DisclosureController extends Controller {
  isOpen: boolean;
  target: Element | null;
  targetInitialHidden = false;

  connect(): void {
    this.isOpen = this.element.hasAttribute('aria-expanded')
      ? this.element.getAttribute('aria-expanded') === 'true'
      : false;
    this.target = getElementFromSelector(this.element as HTMLElement);

    if (this.target) {
      this.element.addEventListener('click', () => {
        this.toggle(!this.isOpen);
      });

      if (this.target.hasAttribute('hidden')) {
        this.targetInitialHidden = true;
      }
    }
  }

  toggle(state: boolean): void {
    this.isOpen = state;
    this.element.setAttribute('aria-expanded', this.isOpen.toString());
    if (this.targetInitialHidden) {
      this.target?.toggleAttribute('hidden', !this.isOpen);
    }
  }
}
