import { Node } from '@tiptap/core';

const Title = Node.create({
  name: 'title',
  content: 'inline*',
  group: 'block',

  parseHTML() {
    return [
      {
        tag: 'h1',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['h1', HTMLAttributes, 0];
  },
});

export default Title;
