import { Controller } from '@hotwired/stimulus';
import tippy, { Instance as tippyInstances } from 'tippy.js';
import { hasFinePointer, mediaBreakpointUp } from '../utils/media';

/* stimulusFetch: 'lazy' */
export default class HoverCardController extends Controller<HTMLFormElement> {
  declare urlValue: string;
  static values = {
    url: String,
  };

  popover: tippyInstances;

  connect() {
    if (hasFinePointer() && mediaBreakpointUp('md')) {
      this.initHovercard();
    }
  }

  initHovercard() {
    // Setup hover card
    const hoverCardUrl = this.urlValue;
    this.popover = tippy(this.element, {
      theme: 'popover',
      appendTo: () => document.body,
      content: '<div class="popover__body"><p>Loading...</p></div>',
      allowHTML: true,
      interactive: true,
      placement: 'top-start',
      animation: 'fade',
      arrow: false,
      delay: [500, null],
      showOnCreate: false,
      onCreate(instance) {
        // Setup our own custom state properties
        instance._isFetching = false;
        instance._error = null;
      },
      onShow(instance) {
        if (instance._isFetching || instance._error) {
          return;
        }

        instance._isFetching = true;

        fetch(hoverCardUrl)
          .then((response) => {
            if (response.ok) {
              return response.text();
            }
          })
          .then((htmlSnippet) => {
            instance.setContent(htmlSnippet || '');
          })
          .catch((error) => {
            instance._error = error;
            instance.setContent(`Request failed. ${error}`);
          })
          .finally(() => {
            instance._isFetching = false;
          });
      },
      onHidden(instance) {
        instance.setContent('<div class="popover__body"><p>Loading...</p></div>');
        instance._error = null;
      },
    });
  }
}
