import { Controller } from '@hotwired/stimulus';

export default class FullscreenController extends Controller {
  fullscreenControl: HTMLElement | null = null;

  connect(): void {
    this.fullscreenControl = this.element.querySelector('[data-action="fullscreen#toggle"]');
    if (!document.fullscreenEnabled) {
      this.fullscreenControl?.remove();
    }
  }

  toggle() {
    if (!document.fullscreenElement) {
      this.element.requestFullscreen();
      this.fullscreenControl?.setAttribute('aria-pressed', 'true');
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      this.fullscreenControl?.setAttribute('aria-pressed', 'false');
    }
  }
}
