import { Controller } from '@hotwired/stimulus';

import { numberParser, numberFormater } from '../../utils/number';

/* stimulusFetch: 'lazy' */
export default class DocumentDatasheetController extends Controller {
  declare readonly flatAdministrativeTransferFeeTarget: HTMLInputElement;
  declare readonly totalAmountOfTransactionsTarget: HTMLInputElement;
  declare readonly shareValueTarget: HTMLInputElement;
  declare readonly totalNotaryCostsTarget: HTMLInputElement;
  declare readonly attributableNotaryCostsTarget: HTMLInputElement;
  declare readonly totalCourtCostsTarget: HTMLInputElement;
  declare readonly numberOfTransactionsTarget: HTMLInputElement;
  declare readonly attributableCourtCostsTarget: HTMLInputElement;
  declare readonly flatAdministrativeTransferFeeWithoutVatTarget: HTMLInputElement;
  static targets = [
    'flatAdministrativeTransferFee',
    'totalAmountOfTransactions',
    'shareValue',
    'totalNotaryCosts',
    'attributableNotaryCosts',
    'totalCourtCosts',
    'numberOfTransactions',
    'attributableCourtCosts',
    'flatAdministrativeTransferFeeWithoutVat',
  ];

  connect() {
    this.setFlatAdministrativeTransferFeeWithoutVat();
    this.setAttributableNotaryCosts();
    this.setAttributableCourtCosts();
  }

  setFlatAdministrativeTransferFeeWithoutVat(): void {
    this.flatAdministrativeTransferFeeWithoutVatTarget.innerText = numberFormater(
      Math.round(numberParser(this.flatAdministrativeTransferFeeTarget.value) / 1.19).toString(),
    );
  }

  setAttributableNotaryCosts(): void {
    const totalAmountOfTransactions = numberParser(this.totalAmountOfTransactionsTarget.value);
    const totalNotaryCosts = numberParser(this.totalNotaryCostsTarget.value);
    const shareValue = numberParser(this.shareValueTarget.innerText);

    if (totalAmountOfTransactions && totalNotaryCosts && shareValue) {
      this.attributableNotaryCostsTarget.value = numberFormater(
        ((shareValue / totalAmountOfTransactions) * totalNotaryCosts).toFixed(2).replace('.', ','),
      );
    }
  }

  setAttributableCourtCosts(): void {
    const totalCourtCosts = numberParser(this.totalCourtCostsTarget.value);
    const numberOfTransactions = numberParser(this.numberOfTransactionsTarget.value);

    this.attributableCourtCostsTarget.value = totalCourtCosts && numberOfTransactions
      ? numberFormater((totalCourtCosts / numberOfTransactions).toString())
      : '';
  }
}
