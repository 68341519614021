import { Controller } from '@hotwired/stimulus';
import { executeAfterTransition, reflow } from '../utils/transition';
import SelectorEngine from '../utils/selector-engine';

import { getSelectorFromElement, getElementFromSelector } from '../utils/target-api';

const CLASS_NAME_SHOW = 'show';
const CLASS_NAME_COLLAPSE = 'collapse';
const CLASS_NAME_COLLAPSING = 'collapsing';
const CLASS_NAME_COLLAPSED = 'collapsed';
const CLASS_NAME_HORIZONTAL = 'collapse--horizontal';

/* stimulusFetch: 'lazy' */
export default class CollapseController extends Controller {
  panel: HTMLElement | null;

  isTransitioning = false;
  triggerArray: HTMLElement[] = [];

  connect(): void {
    this.panel = getElementFromSelector(this.element as HTMLElement);

    const toggleList = SelectorEngine.find('[data-controller="collapse"]');

    toggleList.forEach((elem) => {
      const selector = getSelectorFromElement(elem as HTMLElement);
      const filterElement = SelectorEngine.find(selector).filter((foundElement) => foundElement === this.panel);

      if (selector !== null && filterElement.length) {
        this.triggerArray.push(elem);
      }
    });

    if (this.panel) {
      CollapseController.addAriaAndCollapsedClass(this.triggerArray, this.isShown());
      this.element.addEventListener('click', () => this.toggle());
    }
  }

  toggle() {
    if (this.isShown()) {
      this.hide();
    } else {
      this.show();
    }
  }

  show(): void {
    if (this.isTransitioning || this.isShown()) {
      return;
    }

    const dimension = this.getDimension();

    this.panel.classList.remove(CLASS_NAME_COLLAPSE);
    this.panel.classList.add(CLASS_NAME_COLLAPSING);

    this.panel.style[dimension] = '0';

    CollapseController.addAriaAndCollapsedClass(this.triggerArray, true);
    this.isTransitioning = true;

    const complete = () => {
      this.isTransitioning = false;

      this.panel.classList.remove(CLASS_NAME_COLLAPSING);
      this.panel.classList.add(CLASS_NAME_COLLAPSE, CLASS_NAME_SHOW);

      this.panel.style[dimension] = '';
    };

    const capitalizedDimension = dimension[0].toUpperCase() + dimension.slice(1);
    const scrollSize = `scroll${capitalizedDimension}`;

    CollapseController.queueCallback(complete, this.panel, true);
    this.panel.style[dimension] = `${this.panel[scrollSize]}px`;
  }

  hide(): void {
    if (this.isTransitioning || !this.isShown()) {
      return;
    }

    const dimension = this.getDimension();

    this.panel.style[dimension] = `${this.panel.getBoundingClientRect()[dimension]}px`;

    reflow(this.panel);

    this.panel.classList.add(CLASS_NAME_COLLAPSING);
    this.panel.classList.remove(CLASS_NAME_COLLAPSE, CLASS_NAME_SHOW);

    this.triggerArray.forEach((trigger) => {
      const element = getElementFromSelector(trigger);

      if (element && !this.isShown(element)) {
        CollapseController.addAriaAndCollapsedClass(this.triggerArray, false);
      }
    });

    this.isTransitioning = true;

    const complete = () => {
      this.isTransitioning = false;
      this.panel.classList.remove(CLASS_NAME_COLLAPSING);
      this.panel.classList.add(CLASS_NAME_COLLAPSE);
    };

    this.panel.style[dimension] = '';

    CollapseController.queueCallback(complete, this.panel, true);
  }

  isShown(element = this.panel) {
    return element.classList.contains(CLASS_NAME_SHOW);
  }

  getDimension() {
    return this.panel.classList.contains(CLASS_NAME_HORIZONTAL) ? 'width' : 'height';
  }

  static addAriaAndCollapsedClass(triggerArray: Element[], isOpen: boolean) {
    if (!triggerArray.length) {
      return;
    }

    triggerArray.forEach((element) => {
      element.classList.toggle(CLASS_NAME_COLLAPSED, !isOpen);
      element.setAttribute('aria-expanded', isOpen.toString());
    });
  }

  static queueCallback(callback: () => void, element: HTMLElement, isAnimated = true) {
    executeAfterTransition(callback, element, isAnimated);
  }
}
