import 'core-js/stable';
import './styles/app.scss';

// start the Stimulus application
import './bootstrap';

// Give CSS a hint, if JavaScript is enabled
document.documentElement.classList.remove('no-js');
document.documentElement.classList.add('js');

document.addEventListener('turbo:frame-missing', (event) => {
  event.preventDefault();

  const targetFrame = event.target;

  targetFrame.innerHTML = `
    <div class="toast" data-type="error">
      <div class="toast__content">
        <p>Ein Fehler ist beim Laden der neuen Inhalte aufgetreten. Bitte laden Sie die Seite erneut.</p>
        <p>Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an den Support.</p>
      </div>
    </div>
  `;

  const message = `The response (${event.detail.response.statusCode}) did not contain the expected <turbo-frame id="${targetFrame.id}"> and will be ignored. To perform a full page visit instead, set turbo-visit-control to reload.`;
  throw new Error(message);
});
