import * as Turbo from '@hotwired/turbo';
import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class PaginationSelectController extends Controller {
  declare selectTargets: HTMLSelectElement[];
  static targets = ['select'];

  declare hasRouteValue: boolean;
  declare routeValue: string;
  static values = {
    route: String,
  };

  connect() {
    this.selectTargets.forEach((select) => {
      select.addEventListener('change', () => {
        this.setParentFrameOrFilterForm(select.value);
      });
    });
  }

  setParentFrameOrFilterForm(url: string) {
    const filterForm = this.hasRouteValue
      ? (document.querySelector(`form[data-filterForRoute=${this.routeValue}]`) as HTMLFormElement | null)
      : null;
    if (this.hasRouteValue && filterForm) {
      if (filterForm) {
        filterForm.action = url;
        filterForm.requestSubmit();
      }
    } else {
      const parentFrame = this.element.closest('turbo-frame');
      const parentFrameAction = parentFrame?.getAttribute('data-turbo-action');

      if (parentFrame) {
        const options = { frame: parentFrame.id };

        // Check if the frame should set a new url (advance)
        if (parentFrameAction) {
          options.action = parentFrameAction;
        }

        Turbo.visit(url, options);
      } else {
        Turbo.visit(url, { action: 'advance' });
      }
    }
  }

  changePage(event: PointerEvent) {
    event.preventDefault();
    const link = event.target as HTMLAnchorElement;

    this.setParentFrameOrFilterForm(link.href);
  }
}
