const toastContainer = document.querySelector('#toast-container');

const createFlashMessage = (
  type: string,
  title: string,
  content: string | undefined = undefined,
  autoDismiss: number | undefined = undefined,
) => {
  const template = `
    <div class="toast is-animated" data-controller="toast" ${autoDismiss ? `data-toast-auto-dismiss-value="${autoDismiss}"` : ''} data-type="${type}">
      <svg class="icon toast__icon" role="img" aria-hidden="true"><use xlink:href="/assets/images/icons.svg#i-${type}"></use></svg>
      <div class="toast__content prose">
        <h2 class="h6">${title}</h2>
        ${!content ? '' : content}
      </div>
      <div class="toast__close">
        <button class="btn btn--bare btn--icon" data-action="toast#close">
          <svg class="icon" role="img" aria-hidden="true"><use xlink:href="/assets/images/icons.svg#i-close"></use></svg>
        </button>
      </div>
    </div>`;
  toastContainer?.insertAdjacentHTML('beforeend', template);
};

export { createFlashMessage };

export default createFlashMessage;
