import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class ClearAndDeactivateController extends Controller<HTMLInputElement> {
  declare inputIdValue: string;
  static values = {
    inputId: String,
  };

  inputEl: HTMLElement | null;

  connect(): void {
    this.inputEl = document.getElementById(this.inputIdValue);

    if (this.inputEl && this.inputEl instanceof HTMLInputElement) {
      this.element.addEventListener('change', () => {
        if (this.element.checked) {
          this.inputEl.value = '';
          this.inputEl?.setAttribute('disabled', 'disabled');
        } else {
          this.inputEl?.removeAttribute('disabled');
        }
      });
    }
  }
}
