import L from 'leaflet';
import { getJson } from '../../utils/api';
import MapController, { Facility, FACILITY_ICON } from './base_controller';

/* stimulusFetch: 'lazy' */
export default class MapParkFacilitiesController extends MapController {
  declare facilityUrlValue: string | undefined;
  declare yearValue: number | undefined;
  static values = {
    facilityUrl: String,
    year: Number,
  };

  declare infoboxTarget: HTMLElement;
  static targets = ['infobox'];

  facilities: Facility[] = [];
  activeFacility: Facility | null = null;
  initialZoom: number | undefined = undefined;

  connect() {
    super.connect();
    this.getFacilities();
  }

  getFacilities() {
    if (this.facilityUrlValue) {
      getJson(this.facilityUrlValue)
        .then((facilities: Array<Facility>) => {
          facilities.forEach((facility) => {
            if (this.showFacility(facility)) {
              this.facilities.push(facility);
            }
          });
          if (this.facilities.length > 0) {
            this.addFacilitiesToMap();
          } else {
            this.setMissingState();
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  }

  showFacility(facility: Facility) {
    return (
      (facility.initialStartupAt ? parseInt(facility.initialStartupAt.slice(0, 4), 10) <= this.yearValue : false)
      && (facility.finalDecomissioningAt === null
        || this.yearValue < parseInt(facility.finalDecomissioningAt.slice(0, 4), 10))
      && facility.location
    );
  }

  addFacilitiesToMap() {
    this.facilities.forEach((facility) => {
      if (this.showFacility(facility)) {
        const marker = L.marker(facility.location, {
          icon: FACILITY_ICON,
        }).addTo(this.map);

        marker.bindTooltip(facility.displayName);
        marker.on('click', () => this.onClick(facility));
      }
    });

    this.map?.fitBounds(this.bounds(this.facilities));
    this.initialZoom = this.map?.getZoom();
    this.infoboxTarget.hidden = false;
  }

  bounds(facilities) {
    const bounds: [] = [];

    facilities.forEach((facility) => {
      bounds.push(facility.location);
    });

    return bounds;
  }

  onClick(facility: Facility) {
    const infobox = `
      <div class="card">
        <header class="card__header">
          <h3 class="card__title">${facility.displayName} <span class="tag" data-tag-theme="success">${facility.stateTranslated}</span></h3>
        </header>
        <div class="card__body">
          <dl>
            <dt>Typ</dt>
            <dd>${facility.manufacturer} ${facility.typeOfficial}</dd>
            <dt>Inbetriebnahme</dt>
            <dd>${facility.initialStartupAt ? new Intl.DateTimeFormat('de-de', {}).format(new Date(facility.initialStartupAt)) : ''}</dd>
            <dt>Leistung</dt>
            <dd>${facility.capacity} MW</dd>
            <dt>Nabenhöhe</dt>
            <dd>${facility.hubHeight} MW</dd>
            <dt>Rotorendurchmesser</dt>
            <dd>${facility.rotorDiameter} MW</dd>
          </dl>
        </div>
      </div>
    `;

    this.activeFacility = facility;
    this.infoboxTarget.innerHTML = infobox;
  }

  setMissingState() {
    this.map?.remove();
    this.infoboxTarget.innerHTML = '<p>Es liegen aktuell keine Anlagen vor, die hier angezeigt werden können.</p>';
    this.infoboxTarget.hidden = false;
  }
}
