import { Controller } from '@hotwired/stimulus';
import Fuse from 'fuse.js';
import { anchorPositioning } from '../../utils/dropdown';

import {
  AutocompleteOption,
  getSelectOptions,
  getSelectOptionByValue,
  clearOptions,
  getNextSelectableOption,
  getPreviousSelectableOption,
  getFirstSelectableOption,
  getLastSelectableOption,
} from '../../utils/select';

import { isScrollable, maintainScrollVisibility } from '../../utils/scroll';

/* stimulusFetch: 'lazy' */
export default class ComboboxController extends Controller {
  declare hasRemoveElTarget: boolean;
  declare removeElTarget: HTMLElement;
  static targets = ['removeEl'];

  declare selectEl: HTMLSelectElement;
  declare selectedEl: HTMLUListElement;
  declare comboEl: HTMLElement;
  declare textBoxEl: HTMLInputElement;
  declare listboxEl: HTMLElement;
  declare statusEl: HTMLElement;

  declare activeOptionId: string | null;
  declare open: boolean;
  declare ignoreBlur: boolean;
  declare baseId: string;

  connect(): void {
    this.selectEl = this.element.querySelector('select') as HTMLSelectElement;
    this.baseId = this.selectEl.id;
    this.open = false;

    this.createTextBox();
    this.createMenu();
    this.createSelectedList();
    anchorPositioning(this.textBoxEl, this.listboxEl);
    this.hideSelectBox();
    this.createStatusBox();
    this.setupMutationObserver();

    // Select inital options
    const options = getSelectOptions(this.selectEl);
    const selectedOptions = Array.from(this.selectEl.options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    options.forEach((option) => {
      if (selectedOptions.includes(option.value)) {
        this.selectOption(option.value);
      }
    });

    // Close menu if click is not in the controller
    document.addEventListener(
      'click',
      (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (this.open && !target?.closest('[data-controller="input--combobox"]')) {
          this.updateMenuState(false, true);
        }
      },
      false,
    );
  }

  createSelectedList(): void {
    this.selectedEl = document.createElement('ul');
    this.selectedEl.id = `${this.baseId}-selected`;
    this.selectedEl.classList.add('list', 'list--horizontal', 'mt--2xs');
    this.element.append(this.selectedEl);
  }

  createTextBox(): void {
    this.comboEl = document.createElement('div');
    this.comboEl.classList.add('picklist__wrapper', 'dropdown-trigger', 'dropdown-trigger--click');

    this.textBoxEl = document.createElement('input');
    this.textBoxEl.setAttribute('type', 'text');
    this.textBoxEl.setAttribute('role', 'combobox');
    this.textBoxEl.setAttribute('aria-controls', `listbox-${this.baseId}`);
    this.textBoxEl.setAttribute('aria-expanded', 'false');
    this.textBoxEl.setAttribute('aria-haspopup', 'listbox');
    this.textBoxEl.setAttribute('aria-activedescendant', '');
    this.textBoxEl.setAttribute('aria-autocomplete', 'none');
    this.textBoxEl.setAttribute('autocomplete', 'off');
    this.textBoxEl.setAttribute('autocapitalize', 'none');
    this.textBoxEl.setAttribute('aria-describedby', `${this.baseId}-selected`);
    this.textBoxEl.id = this.baseId;
    this.textBoxEl.classList.add('input', 'picklist__input');

    if (this.selectEl.disabled) {
      this.textBoxEl.disabled = true;
    }

    // Add empty Option als placeholder text
    const placeholderOption = this.selectEl.querySelector('option[value=""]');
    if (placeholderOption && placeholderOption.textContent) {
      this.textBoxEl.setAttribute('placeholder', placeholderOption.textContent);
    }

    this.comboEl.append(this.textBoxEl);
    this.element.append(this.comboEl);

    // add event listeners
    this.textBoxEl.addEventListener('click', () => {
      this.buildMenu(getSelectOptions(this.selectEl));
      this.updateMenuState(true);
    });
    this.textBoxEl.addEventListener('keydown', (event) => {
      const first = getFirstSelectableOption(this.listboxEl);
      const last = getLastSelectableOption(this.listboxEl);

      switch (event.key) {
        case 'Tab':
          this.onElementLeave();
          break;
        case 'Home':
          if (this.open && first) {
            event.preventDefault();
            this.highlightOption(first);
          }
          break;
        case 'ArrowDown':
          this.onTextBoxDownArrow(event);
          break;
        case 'ArrowUp':
          this.onTextBoxUpArrow(event);
          break;
        case 'End':
          if (this.open && last) {
            event.preventDefault();
            this.highlightOption(last);
          }
          break;
        case 'Enter':
          event.preventDefault();
          break;
        default:
          break;
      }
    });
    this.textBoxEl.addEventListener('keyup', (event) => this.onTextBoxKeyUp(event));
  }

  createMenu(): void {
    this.listboxEl = document.createElement('ul');
    this.listboxEl.setAttribute('role', 'listbox');
    this.listboxEl.setAttribute('aria-multiselectable', 'true');
    this.listboxEl.id = `listbox-${this.baseId}`;
    this.listboxEl.classList.add('listbox', 'listbox--vertical', 'dropdown');
    this.listboxEl.dataset.size = 'fluid';
    this.listboxEl.dataset.length = '5';
    this.comboEl.append(this.listboxEl);
  }

  hideSelectBox(): void {
    this.selectEl.setAttribute('aria-hidden', 'true');
    this.selectEl.setAttribute('tabindex', '-1');
    this.selectEl.hidden = true;
    this.selectEl.removeAttribute('id');
  }

  createStatusBox(): void {
    this.statusEl = document.createElement('div');
    this.statusEl.setAttribute('aria-live', 'polite');
    this.statusEl.setAttribute('role', 'status');
    this.statusEl.hidden = true;
    this.element.append(this.statusEl);
  }

  updateStatus(optionsCount: number, disabledOptionsCount: number | null = null): void {
    if (optionsCount === 0) {
      this.statusEl.innerText = 'No results.';
    } else {
      this.statusEl.innerText = `
        ${optionsCount} option${optionsCount !== 1 ? 's' : ''} available${disabledOptionsCount ? `, ${disabledOptionsCount} disabled option${disabledOptionsCount !== 1 ? 's' : ''}` : ''}.`;
    }
  }

  setupMutationObserver(): void {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const options = getSelectOptions(this.selectEl);
          this.selectedEl.innerHTML = '';
          const selectedOptions = Array.from(this.selectEl.options)
            .filter((option) => option.selected)
            .map((option) => option.value);
          options.forEach((option) => {
            if (selectedOptions.includes(option.value)) {
              this.selectOption(option.value);
            }
          });
        }
        if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
          this.textBoxEl.disabled = this.selectEl.disabled;
        }
      });
    });

    observer.observe(this.selectEl, { childList: true, attributes: true });
  }

  buildMenu(options: AutocompleteOption[]): void {
    clearOptions(this.listboxEl);

    options.forEach((option) => {
      const optionEl = document.createElement('li');
      optionEl.setAttribute('role', 'option');
      optionEl.id = `${this.baseId}-${option.value}`;
      optionEl.classList.add('listbox__option', 'listbox__option--plain');
      optionEl.setAttribute('aria-selected', option.selected.toString());
      if (option.disabled) {
        optionEl.setAttribute('aria-disabled', 'true');
      }
      optionEl.dataset.optionValue = option.value;
      if (option.text) {
        optionEl.innerText = option.text;
      }

      optionEl.addEventListener('click', (event) => this.onOptionClick(event.currentTarget as HTMLLIElement));
      optionEl.addEventListener('mousedown', () => this.onOptionMouseDown());

      this.listboxEl?.appendChild(optionEl);
    });

    const activeOption = this.activeOptionId ? (document.getElementById(this.activeOptionId) as HTMLLIElement) : null;
    if (activeOption) {
      this.highlightOption(activeOption);
    }
  }

  getFilteredOptions(value: string): AutocompleteOption[] {
    return new Fuse(getSelectOptions(this.selectEl), {
      keys: ['text'],
      threshold: 0.4,
    })
      .search(value)
      .flatMap(({ item }) => item);
  }

  onTextBoxKeyUp(event: KeyboardEvent): void {
    switch (event.key) {
      // ignore these keys otherwise
      // the menu will show briefly
      case 'ArrowLeft':
      case 'ArrowRight':
      case ' ':
      case 'Shift':
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      case 'Enter':
        this.onTextBoxEnter(event);
        break;
      case 'Escape':
        event.preventDefault();
        this.updateMenuState(false);
        break;
      default:
        this.onTextBoxType();
    }
  }

  onTextBoxType(): void {
    if (this.textBoxEl.value.trim().length > 0) {
      const options = this.getFilteredOptions(this.textBoxEl.value.trim());
      const disabledOptions = options.filter((option) => option.disabled);
      this.buildMenu(options);
      this.updateMenuState(true);

      this.updateStatus(options.length, disabledOptions.length);

      // Select first option
      const firstOption = getFirstSelectableOption(this.listboxEl);
      if (options.length > 0 && firstOption) {
        this.highlightOption(firstOption);
      }

      const menuState = options.length > 0;
      if (this.open !== menuState) {
        this.updateMenuState(menuState, false);
      }
    } else {
      this.updateMenuState(false, true);
    }
  }

  onTextBoxDownArrow(event: KeyboardEvent): void {
    event.preventDefault();

    if (this.open) {
      const nextOption = this.activeOptionId
        ? getNextSelectableOption(document.getElementById(this.activeOptionId) as HTMLLIElement)
        : getFirstSelectableOption(this.listboxEl);
      if (nextOption) {
        this.highlightOption(nextOption);
      }
    } else {
      this.updateMenuState(true, false);
    }
  }

  onTextBoxUpArrow(event: KeyboardEvent): void {
    event.preventDefault();

    if (this.activeOptionId) {
      const previousOption = this.activeOptionId
        ? getPreviousSelectableOption(document.getElementById(this.activeOptionId) as HTMLLIElement)
        : null;
      if (previousOption) {
        this.highlightOption(previousOption);
      } else {
        // Close menu
        this.updateMenuState(false, true);
      }
    }
  }

  onTextBoxEnter(event: KeyboardEvent): void {
    event.preventDefault();
    if (this.open) {
      const activeOption = this.activeOptionId ? (document.getElementById(this.activeOptionId) as HTMLLIElement) : null;
      if (activeOption) {
        this.updateOption(activeOption);
      } else {
        this.updateMenuState(false);
      }
    } else {
      this.updateMenuState(true);
    }
  }

  onElementLeave() {
    if (this.ignoreBlur) {
      this.ignoreBlur = false;
      return;
    }

    if (this.open) {
      this.updateMenuState(false, false);
    }
  }

  highlightOption(optionEl: HTMLLIElement): void {
    this.activeOptionId = optionEl.id;
    this.textBoxEl.setAttribute('aria-activedescendant', optionEl.id);

    // update active style
    const options = this.element.querySelectorAll('[role=option]') as NodeListOf<HTMLElement>;
    options.forEach((option) => {
      option.setAttribute('data-current', 'false');
      option.classList.remove('has-focus');
    });
    optionEl.setAttribute('data-current', 'true');
    optionEl.classList.add('has-focus');

    if (this.open && isScrollable(this.listboxEl)) {
      maintainScrollVisibility(optionEl, this.listboxEl);
    }
  }

  onOptionClick(option: HTMLLIElement): void {
    if (option.getAttribute('aria-disabled') !== 'true') {
      this.highlightOption(option);
      this.updateOption(option);
      this.textBoxEl.focus();
    }
  }

  onOptionMouseDown(): void {
    this.ignoreBlur = true;
  }

  removeOption(value: string): void {
    const optionEl = document.getElementById(`${this.baseId}-${value}`) as HTMLLIElement;

    // update aria-selected
    if (optionEl) {
      optionEl.setAttribute('aria-selected', 'false');
    }

    // remove selected option
    const itemEl = document.getElementById(`${this.baseId}-${value}-remove`);
    if (itemEl) {
      this.selectedEl.removeChild(itemEl.parentElement?.parentElement as HTMLElement);
    }

    // Update select element
    const selectOption = getSelectOptionByValue(this.selectEl, value);
    if (selectOption) {
      selectOption.selected = false;
      selectOption.removeAttribute('selected');
    }
  }

  selectOption(value: string): void {
    this.activeOptionId = `${this.baseId}-${value}`;
    const selectOption = getSelectOptionByValue(this.selectEl, value);

    // update aria-selected
    const optionEl = document.getElementById(this.activeOptionId);
    if (optionEl) {
      optionEl.setAttribute('aria-selected', 'true');
    }

    if (!this.hasRemoveElTarget) {
      console.warn('No remove element target found');
    } else if (selectOption) {
      // add remove option button
      const listItem = document.createElement('li');
      const tagItem = this.removeElTarget.cloneNode(true) as HTMLElement;
      tagItem.hidden = false;
      tagItem.removeAttribute('data-input--combobox-target');
      const tagLabelEl = tagItem.querySelector('.tag__label') as HTMLElement;
      tagLabelEl.innerHTML = selectOption.innerText;
      tagLabelEl.setAttribute('id', `${this.activeOptionId}-text`);

      const buttonEl = tagItem.querySelector('button') as HTMLButtonElement;
      buttonEl.type = 'button';
      buttonEl.id = `${this.activeOptionId}-remove`;
      buttonEl.setAttribute('aria-labelledby', `Remove: ${this.activeOptionId}-text`);
      buttonEl.addEventListener('click', () => {
        this.removeOption(value);
      });

      listItem.appendChild(tagItem);
      this.selectedEl.appendChild(listItem);
    }

    // Update select element
    if (selectOption) {
      selectOption.selected = true;
    }
  }

  updateOption(option: HTMLLIElement) {
    const isSelected = option.getAttribute('aria-selected') === 'true';

    if (isSelected) {
      this.removeOption(option.dataset.optionValue as string);
    } else {
      this.selectOption(option.dataset.optionValue as string);
    }

    this.textBoxEl.value = '';
    this.buildMenu(getSelectOptions(this.selectEl));
  }

  updateMenuState(open: boolean, callFocus = true) {
    this.open = open;

    this.textBoxEl?.setAttribute('aria-expanded', `${open}`);

    if (open) {
      this.comboEl.classList.add('is-open');
    } else {
      this.comboEl.classList.remove('is-open');
    }

    if (callFocus) {
      this.textBoxEl.focus();
    }
  }
}
