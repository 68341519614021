import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class DisclosureController extends Controller {
  declare toggleTarget: HTMLButtonElement;
  static targets = ['toggle'];

  connect() {
    this.toggleTarget.addEventListener('click', () => {
      this.open();
    });
  }

  open() {
    this.element.classList.add('is-open');
  }
}
