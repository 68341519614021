import { Controller } from '@hotwired/stimulus';
import { validateInputGroup, updateValidationStateForInput } from '../utils/validation';

/* stimulusFetch: 'lazy' */
export default class ValidateFormElement extends Controller<HTMLDivElement | HTMLFieldSetElement> {
  connect() {
    const groupedInputFields = this.element.querySelectorAll(
      'input[type="checkbox"], input[type="radio"]',
    ) as NodeListOf<HTMLInputElement>;
    const inputFields = this.element.querySelector('input:not([type="checkbox"], [type="radio"]), textarea') as
      | HTMLInputElement
      | null;
    const selectFields = this.element.querySelector('select') as
      | HTMLSelectElement
      | null;

    if (inputFields) {
      // Set up `blur` and `input` validation for the inputs that can be
      // validated with the Constraint Validation API.
      inputFields.addEventListener('input', (event) => updateValidationStateForInput(event.target as HTMLInputElement));
      inputFields.addEventListener('blur', (event) => updateValidationStateForInput(event.target as HTMLInputElement));

      // Should be set to "false" before any validation occurs.
      inputFields.setAttribute('aria-invalid', 'false');

      // Update the state for prefilled inputs.
      // if (inputFields.value !== '') {
      //   updateValidationStateForInput(inputFields);
      // }
    }

    if (selectFields) {
      // Set up `blur` and `change` validation for the selects that can be validated with the Constraint Validation API.
      selectFields.addEventListener('blur', (event) => updateValidationStateForInput(event.target as HTMLInputElement));
      selectFields.addEventListener('change', (event) => updateValidationStateForInput(event.target as HTMLInputElement));

      // Should be set to "false" before any validation occurs.
      selectFields.setAttribute('aria-invalid', 'false');
    }

    // Set up event listeners to validate checkbox groups
    groupedInputFields?.forEach((checkboxInputEl) => {
      // Updates the UI state for the checkbox group when checked/unchecked
      checkboxInputEl.addEventListener('change', (event) => validateInputGroup(event.target as HTMLInputElement));

      // Set up late validation for the checkbox group
      checkboxInputEl.addEventListener('blur', (event) => {
        // FocusEvent.relatedTarget is the element receiving focus.
        const activeEl = event.relatedTarget as HTMLInputElement;
        // Validate only if the focus is not going to another checkbox.
        if (activeEl?.getAttribute('name') !== checkboxInputEl.name) {
          validateInputGroup(checkboxInputEl);
        }
      });
    });
  }
}
