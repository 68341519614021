import { Node, mergeAttributes } from '@tiptap/core';

export type PlacholderInlineAtts = {
  id: string;
  label: string;
  value: string;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    inlinePlaceholder: {
      /**
       * Insert an inline placeholder
       */
      insertInlinePlaceholder: (attrs: PlacholderInlineAtts) => ReturnType;
    };
  }
}

const decodeHTML = function (html: string) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

const ContentPlacebolderInline = Node.create({
  name: 'placeholderinline',

  group: 'inline',
  inline: true,

  atom: true,
  selectable: true,
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ node }) {
        return `${decodeHTML(node.attrs.value) ?? node.attrs.title}`;
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-placeholder-id'),
        renderHTML: (attributes) => ({
          'data-placeholder-id': attributes.id,
        }),
      },
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('title'),
        renderHTML: (attributes) => ({
          title: attributes.label,
        }),
      },
      value: {
        default: null,
        parseHTML: (element) => element.innerText,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-placeholder-id]',
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ];
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    });
  },

  addCommands() {
    return {
      insertInlinePlaceholder:
        (attrs: PlacholderInlineAtts) => ({ state, dispatch }) => {
          const { selection } = state;
          const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
          const node = this.type.create(attrs);
          const transaction = state.tr.insert(position, node);
          dispatch(transaction);
        },
    };
  },
});

export default ContentPlacebolderInline;
