import { Controller } from '@hotwired/stimulus';

export default class ToastController extends Controller {
  declare container: HTMLElement | null;

  declare autoDismissValue: number;
  static values = {
    autoDismiss: Number,
  };

  isMouseHover = false;
  timer: ReturnType<typeof setTimeout> | null = null;

  connect() {
    this.container = this.element.closest('.toast__container');

    this.element.addEventListener('mouseleave', () => {
      this.isMouseHover = false;
      if (this.autoDismissValue) {
        this.startTimer();
      }
    });

    this.element.addEventListener('mouseover', () => {
      this.isMouseHover = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }
    });

    if (this.autoDismissValue) {
      this.startTimer();
    }
  }

  startTimer() {
    this.timer = setTimeout(() => {
      this.close();
    }, this.autoDismissValue);
  }

  close() {
    if (this.container) {
      this.element.addEventListener('animationend', () => {
        this.container?.removeChild(this.element);
      });
      this.element.classList.add('is-dismissed');
    }
  }

  closeAll() {
    const removeAllChildNodes = function (parent: HTMLElement) {
      while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
      }
    };

    if (this.container) {
      removeAllChildNodes(this.container);
    }
  }
}
