import { Controller } from '@hotwired/stimulus';
import { getJson } from '../../utils/api';
import { replaceOptions } from '../../utils/select';

/* stimulusFetch: 'lazy' */
export default class ServiceeventController extends Controller {
  declare readonly facilityTarget: HTMLInputElement;
  declare readonly facilityStatuscodesTarget: HTMLSelectElement;
  static targets = ['facility', 'facilityStatuscodes'];

  declare readonly urlValue: string;
  static values = {
    url: String,
  };

  maybeLoadStatuscodes(): void {
    const url = this.urlValue.replace('1', this.facilityTarget.value);
    const helpElement = this.facilityStatuscodesTarget.parentElement.parentElement.querySelector('.form-element__help');

    if (this.facilityTarget.value === '') {
      this.facilityStatuscodesTarget.replaceChildren();
      this.facilityStatuscodesTarget.disabled = true;
      this.facilityStatuscodesTarget.value = '';
      if (helpElement) {
        helpElement.innerHTML = 'Wählen Sie zuerst eine Anlage aus.';
      }
    } else {
      getJson(url, {})
        .then((data) => {
          if (helpElement) {
            helpElement.innerHTML = '';
          }
          replaceOptions(this.facilityStatuscodesTarget, data);
          this.facilityStatuscodesTarget.value = '';
          this.facilityStatuscodesTarget.disabled = false;
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  }
}
