import * as Turbo from '@hotwired/turbo';
import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class ModalFormController extends Controller {
  connect() {
    document.addEventListener('turbo:before-fetch-response', (event) => {
      const { fetchResponse } = event.detail;
      if (fetchResponse.succeeded && fetchResponse.redirected) {
        event.preventDefault();
        Turbo.visit(fetchResponse.location);
      }
    });
  }
}
