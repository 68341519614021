import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class DateController extends Controller {
  declare inputEl: HTMLInputElement | null;

  connect(): void {
    this.inputEl = this.element.querySelector('input');
  }

  setCurrentDate() {
    if (this.inputEl) {
      const date = new Date();
      this.inputEl.value = `${date.getFullYear().toString()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    }
  }
}
