import { Controller } from '@hotwired/stimulus';

type FileInfo = {
  url: string;
  filename: string | null;
};

/* stimulusFetch: 'lazy' */
export default class NewsFileUploadController extends Controller {
  declare inputTarget: HTMLInputElement;
  declare hasDropzoneTarget: boolean;
  declare dropzoneTarget: HTMLDivElement;
  declare hasProgressBarTarget: boolean;
  declare progressBarTarget: HTMLProgressElement;
  declare hasPreviewTarget: boolean;
  declare previewTarget: HTMLDivElement;
  static targets = ['input', 'dropzone', 'progressBar', 'preview'];

  declare urlValue: string;
  declare filenameReplacePatternValue: string;
  declare maxFilenameLengthValue: number;
  static values = {
    url: String,
    filenameReplacePattern: String,
    maxFilenameLength: Number,
  };

  declare uploadProgress: string[];
  declare files: FileInfo[];

  connect(): void {
    this.files = [];
    this.uploadProgress = [];

    this.setupInputs();
  }

  setupInputs(): void {
    if (this.hasDropzoneTarget) {
      const preventDefaults = (e: Event): void => {
        e.preventDefault();
        e.stopPropagation();
      };

      // Prevent default drag behaviors
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        this.dropzoneTarget.addEventListener(eventName, (e) => preventDefaults(e), false);
        document.body.addEventListener(eventName, (e) => preventDefaults(e), false);
      });

      // Highlight drop area when item is dragged over it
      ['dragenter', 'dragover'].forEach((eventName: string) => {
        this.dropzoneTarget.addEventListener(
          eventName,
          () => this.dropzoneTarget.classList.add('has-drag-over'),
          false,
        );
      });

      ['dragleave', 'drop'].forEach((eventName: string) => {
        this.dropzoneTarget.addEventListener(
          eventName,
          () => this.dropzoneTarget.classList.remove('has-drag-over'),
          false,
        );
      });

      // Handle dropped files
      this.dropzoneTarget.addEventListener('drop', (e) => this.handleDrop(e), false);
    }

    this.inputTarget.addEventListener('change', () => {
      if (this.inputTarget.files) {
        this.handleFiles(this.inputTarget.files);
        this.inputTarget.value = '';
      }
    });
  }

  handleDrop(e: DragEvent) {
    const dt = e.dataTransfer;

    if (dt) {
      const { files } = dt;

      this.handleFiles(files);
    }
  }

  initializeProgress(numFiles: number) {
    if (this.hasProgressBarTarget) {
      this.progressBarTarget.value = 0;
    }
    this.uploadProgress = [];

    for (let i = numFiles; i > 0; i -= 1) {
      this.uploadProgress.push('0');
    }
  }

  updateProgress(fileNumber: number, percent: number) {
    const total = this.uploadProgress.reduce((tot, curr) => tot + parseInt(curr, 10), 0) / this.uploadProgress.length;
    this.uploadProgress[fileNumber] = percent.toString();
    if (this.hasProgressBarTarget) {
      this.progressBarTarget.value = total;
    }
  }

  handleFiles(filelist: FileList): void {
    const files = [...filelist];
    this.initializeProgress(files.length);
    files.forEach((file, i) => this.uploadFile(file, i));
  }

  removeFile(btn: HTMLButtonElement): void {
    const item = btn.closest('.item') as HTMLDivElement;
    this.previewTarget.removeChild(item);
  }

  uploadFile(file: File, i: number): void {
    const fileName = file.name.replaceAll(' ', '_').replaceAll(new RegExp(this.filenameReplacePatternValue, 'g'), '-');
    const fileExtension = fileName.includes('.') ? fileName.substring(fileName.lastIndexOf('.') + 1) : false;
    let truncatedFileName = fileName
      .substring(0, fileName.includes('.') ? fileName.lastIndexOf('.') : undefined)
      .substring(0, this.maxFilenameLengthValue - (fileExtension ? fileExtension.length + 1 : 0));
    truncatedFileName = fileExtension ? `${truncatedFileName}.${fileExtension}` : truncatedFileName;

    const url = this.urlValue.replace('--fileName--', truncatedFileName);
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);

    // Update progress
    xhr.upload.addEventListener('progress', (e) => {
      this.updateProgress(i, (e.loaded * 100.0) / e.total || 100);
    });

    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        this.updateProgress(i, 100);

        const turboFrame = this.hasPreviewTarget ? this.previewTarget : this.element.closest('turbo-frame');
        turboFrame?.reload();
      } else if (xhr.readyState === 4 && xhr.status !== 200) {
        // Error. Inform the user
      }
    });

    xhr.send(file);
  }
}
