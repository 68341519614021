const customEvent = (type: string, detail?: string | object): CustomEvent<unknown> => new CustomEvent(type, {
  bubbles: true,
  cancelable: true,
  detail,
});

/**
 * Emit a custom event
 * @param  {String} type   The event type
 * @param  {*}      detail Any details to pass along with the event
 */
const dispatch = (type: string, detail?: string | object) => document.dispatchEvent(customEvent(type, detail));

export { customEvent, dispatch };
