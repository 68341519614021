import * as Turbo from '@hotwired/turbo';
import { Controller } from '@hotwired/stimulus';
import { setRouteSetting } from '../utils/route-settings';

/* stimulusFetch: 'lazy' */
export default class ColumnToggleController extends Controller {
  static targets = ['submit'];
  declare readonly submitTarget: HTMLButtonElement;

  route: string | undefined = undefined;

  connect() {
    this.route = document.body.dataset.route;
  }

  visibleHeaders(): string[] {
    const headers = [] as string[];
    const activeCheckboxed = this.element?.querySelectorAll('input[type="checkbox"]:checked');
    // biome-ignore lint/complexity/noForEach: <explanation>
    activeCheckboxed?.forEach((checkbox) => {
      headers.push(checkbox.id.substring(3));
    });

    return headers;
  }

  persistState(visibleHeaders: string[] | null) {
    const frame = this.element.closest('turbo-frame');
    if (this.route && frame) {
      setRouteSetting(this.route, 'visible_headers', visibleHeaders).then(() => {
        frame.src = frame?.getAttribute('data-src');
      });
    }
  }

  showColumns() {
    this.persistState(this.visibleHeaders());
  }

  resetColumns() {
    this.persistState(null);
  }
}
